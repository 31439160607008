import React, { FunctionComponent } from "react";
import NumberFormat from "react-number-format";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import "./vaultTable.scss";
import { Link } from "react-router-dom";

type dataObj = {
    address: string;
    name: string;
    symbol: string;
    chain: string;
    tokenLocked: number;
    totalValueLocked: number;
    nextReleaseTime: number;
    type: string;
};

type VaultTableProps = {
    data: Array<dataObj>;
};

const VaultTable: FunctionComponent<VaultTableProps> = ({ data }) => {
    dayjs.extend(relativeTime);
    return (
        <>
            <div className='vault-table-container'>
                <div className="table-container ">
                    <table className='vault-table'>
                        <colgroup>
                            <col span={1} width='auto' />
                            <col span={1} width='auto' />
                            <col span={1} width='auto' />
                            <col span={1} width='auto' />
                            <col span={1} width='auto' />
                            <col span={1} width='20%' />
                        </colgroup>
                        <thead>
                            <tr className="text-center">
                                <th>Name</th>
                                <th>blockchain</th>
                                <th>Tokens Locked</th>
                                <th>total value locked</th>
                                <th>next release</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((obj, index) => (
                                <tr key={`tr-${index}`} className="text-center">
                                    <td >{obj.name.length > 12 ? obj.symbol : obj.name}</td>
                                    <td>{obj.chain}</td>
                                    <td className="text-center">
                                        <NumberFormat
                                            value={Math.floor(obj.tokenLocked * 10000) / 10000}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <NumberFormat
                                            value={obj.totalValueLocked}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />
                                    </td>
                                    <td className="text-center">{obj.nextReleaseTime === 100000000000000 ? 'Released' : dayjs(dayjs.unix(obj.nextReleaseTime).format("YYYY-MM-DD HH:mm:ss")).toNow(true)}</td>
                                    <td className='button-cell'>
                                        {obj.type === "0" && (<Link className='btn btn-brand btn-brand-view' to={"/vault-vesting/" + obj.address}>View</Link>)}
                                        {obj.type === "1" && (<Link className='btn btn-brand btn-brand-view' to={"/vault-lp-vault/" + obj.address}>View</Link>)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default VaultTable;
