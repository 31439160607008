import React, { useState } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import ConnectWallet from '../WalletConnect'
import AccountDetails from '../AccountDetails'
import { useWeb3React } from '@web3-react/core'
import Logo from "./images/logo.png";
import './header.css'
import { Link } from 'react-router-dom'

const Header = () => {
    const [connectWalletModalShow, setConnectWalletModalShow] = useState(false);
    const [accountDetailsModalShow, setAccountDetailsModalShow] = useState(false);
    const { account, active } = useWeb3React();
    const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null;

    function onConnectWalletClick() {
        if (!active) {
            setConnectWalletModalShow(true)
            return
        }
        setAccountDetailsModalShow(true)
    }

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark">
            <Navbar.Brand>
                <Link to="/"><img alt="logo" className="brand-logo" src={Logo}></img></Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                    <Nav className="justify-content-end align-items-center navbar-list" activeKey="/home">
                        <Nav.Item>
                            <Nav.Link href="https://launchpad.blockius.io" className="mr-3" target="_blank">Launchpad</Nav.Link>
                        </Nav.Item>
                        <Nav.Item >
                            <Nav.Link href="https://claim.blockius.io" className="mr-3">Claim</Nav.Link>
                        </Nav.Item>
                        <li className="nav-item text-center dropdown mr-4">
                            <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Staking</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="https://staking.blockius.io/" data-toggle="dropdown" data-target=".dropdown-toggle.in" target="_blank"> BLOS Staking </a></li>
                                <li><a className="dropdown-item" href="https://staking.blockius.io/liquidity-mining" data-toggle="dropdown" data-target=".dropdown-toggle.show" target="_blank">BLOS LP Mining </a></li>
                            </ul>
                        </li>
                        <li className="nav-item text-center dropdown mr-3">
                            <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">  Vault  </a>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/vault?type=vesting" data-toggle="dropdown" data-target=".dropdown-toggle.in"> Vesting </Link></li>
                                <li><Link className="dropdown-item" to="/vault?type=liquidity" data-toggle="dropdown" data-target=".dropdown-toggle.show"> Liquidity </Link></li>
                                {/* <li><Link className="dropdown-item" to="/invdash" data-toggle="dropdown" data-target=".dropdown-toggle.show"> Investor Vesting </Link></li> */}
                            </ul>
                        </li>
                        <Nav.Item>
                            <button className="btn btn-brand-header text-nowrap mr-3 connect-wallet-btn" onClick={onConnectWalletClick}>
                                {!active ? 'CONNECT WALLET' : accountEllipsis}
                            </button>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="btn btn-brand-header text-nowrap apply-btn" href="https://blockius.typeform.com/to/AcD9kxak" target="_blank"> APPLY</Nav.Link>
                            {/* <button className="btn btn-brand-header text-nowrap apply-btn">APPLY</button> */}
                        </Nav.Item>
                    </Nav>
                </Nav>
            </Navbar.Collapse>
            <ConnectWallet
                show={connectWalletModalShow}
                onHide={() => setConnectWalletModalShow(false)}
            />
            <AccountDetails
                show={accountDetailsModalShow}
                onHide={() => setAccountDetailsModalShow(false)}
            />
        </Navbar>
    )
}

export default Header
