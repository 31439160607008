
export const NetworkContextName = 'NETWORK';

export const CHAINID_URL_MAPPING = [{
    chainId: 1,
    chainName: 'ETH',
    name: 'Etherscan',
    currency: 'ETH',
    url: 'https://etherscan.com/'
}, {
    chainId: 3,
    chainName: 'ETH Testnet',
    name: 'Etherscan',
    currency: 'ETH',
    url: 'https://ropsten.etherscan.io/'
}, {
    chainId: 97,
    chainName: 'BSC Testnet',
    name: 'Bscscan',
    currency: 'BNB',
    url: 'https://testnet.bscscan.com/'
}, {
    chainId: 56,
    chainName: 'BSC',
    name: 'Bscscan',
    currency: 'BNB',
    url: 'https://bscscan.com/'
}, {
    chainId: 321,
    chainName: 'KCC',
    name: 'Scan',
    currency: 'KCC',
    url: 'https://scan.kcc.network'
}, {
    chainId: 322,
    chainName: 'KCC Testnet',
    name: 'Scan',
    currency: 'KCC',
    url: 'https://scan-testnet.kcc.network'
}, {
    chainId: 137,
    chainName: 'Polygon',
    name: 'Polygonscan',
    currency: 'MATIC',
    url: 'https://polygonscan.com/'
}, {
    chainId: 80001,
    chainName: 'MATIC Testnet',
    name: 'Polygonscan',
    currency: 'MATIC',
    url: 'https://mumbai.polygonscan.com'
}, {
    chainId: 43114,
    chainName: 'Avalanche',
    name: 'Snowtrace',
    currency: 'AVAX',
    url: 'https://snowtrace.io/'
}, {
    chainId: 43113,
    chainName: 'Avalanche FUJI',
    name: 'Snowtrace',
    currency: 'AVAX',
    url: 'https://testnet.snowtrace.io/'
}, {
    chainId: 66,
    chainName: 'OEC Mainnet',
    name: 'Explorer',
    currency: 'OEC',
    url: 'https://www.oklink.com/oec/'
}, {
    chainId: 65,
    chainName: 'OEC Testnet',
    name: 'Explorer',
    currency: 'OEC',
    url: 'https://www.oklink.com/oec-test/'
}, {
    chainId: 10,
    chainName: 'Optimism',
    name: 'Explorer',
    currency: 'ETH',
    url: 'https://optimistic.etherscan.io/'
}, {
    chainId: 420,
    chainName: 'Optimism Goerli',
    name: 'Explorer',
    currency: 'ETH',
    url: 'https://goerli-optimism.etherscan.io/'
}, {
    chainId: 42161,
    chainName: 'Arbitrum One',
    name: 'Explorer',
    currency: 'ETH',
    url: 'https://arbiscan.io/'
}, {
    chainId: 421613,
    chainName: 'Arbitrum Goerli',
    name: 'Explorer',
    currency: 'ETH',
    url: 'https://goerli.arbiscan.io/'
}]

export const VAULT_PURPOSE_MAPPING = [{
    id: 1,
    name: 'Marketing'
}, {
    id: 2,
    name: 'Ecosystem'
}, {
    id: 3,
    name: 'Team'
}, {
    id: 4,
    name: 'Advisor'
}, {
    id: 5,
    name: 'Foundation'
}, {
    id: 6,
    name: 'Development'
}, {
    id: 7,
    name: 'Partnership'
}, {
    id: 8,
    name: 'Investor'
}]

// ['Marketing', 'Ecosystem', 'Team', 'Advisor', 'Foundation', 'Development', 'Partnership']


