import BigNumber from 'bignumber.js'
// import { ethers } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { MaxUint256 } from '@ethersproject/constants'
import { parseEther } from '@ethersproject/units'

export const approve = async (tokenContract: Contract, vaultAddress: string, account: string) => {
    return tokenContract
        .approve(vaultAddress, MaxUint256)
}

export const createLock = async (vaultContract: Contract, tokenAddress: string, withdrawalAddress: string, amount: string, unlockTime: number, fees: string, purpose: number) => {
    // console.log(tokenAddress, withdrawalAddress, amount, unlockTime)
    return vaultContract.lockTokens(tokenAddress, withdrawalAddress, amount, unlockTime, purpose, {
        value: parseEther(fees)
    })
}

export const createMultipleLock = async (vaultContract: Contract, tokenAddress: string, withdrawalAddress: string, amount: string[], unlockTime: number[], fees: string, purpose: number) => {
    // console.log(tokenAddress, withdrawalAddress, amount, unlockTime)
    return vaultContract.createMultipleLocks(tokenAddress, withdrawalAddress, amount, unlockTime, purpose, {
        value: parseEther(fees)
    })
}

export const withdrawLock = async (vaultContract: Contract, depositId: string) => {
    return vaultContract.withdrawTokens(depositId)
}

export const claimInvDash = async (invDashContract: Contract) => {
    return invDashContract.SelfClaimToMyWallet()
}

export const transferOwnershipInvDash = async (invDashContract: Contract, newBeneficiary: string) => {
    return invDashContract.transferAllLock(newBeneficiary)
}