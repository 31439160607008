import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { formatEther } from '@ethersproject/units'
import { isAddress } from '../utils'
import { approve, createLock, createMultipleLock, withdrawLock } from '../utils/callHelpers'

import { useActiveWeb3React } from './index'
import { getLpVaultContract } from '../utils/contractHelpers'
import { toast } from 'react-toastify';

export interface Token {
    readonly tokenAddress: string;
    readonly decimals: number;
    readonly totalSupply: BigNumber;
    readonly symbol: string;
    readonly name: string;
    readonly balance: BigNumber | undefined;
}

export function useFees(): string | null {
    const { library, account, chainId } = useActiveWeb3React()
    const vaultContract = getLpVaultContract(chainId, library, account)
    const [fees, setFees] = useState<string>()

    useEffect(() => {
        const fetchFees = async () => {
            console.log("fetching fess")
            try {
                const fees = formatEther(await vaultContract?.bnbFee())
                setFees(fees)
            }
            catch (error: any) {
                toast.error(error.message)
                return null
            }
        }

        if (account) {
            fetchFees()
        }
    }, [account, library, chainId])

    if (!fees) {
        return null
    }

    return fees

}

export function useVestingDiscount(): number | null {
    const { library, account, chainId } = useActiveWeb3React()
    const vaultContract = getLpVaultContract(chainId, library, account)
    const [vestingDiscount, setVestingDiscount] = useState<number>()

    useEffect(() => {
        const fetchVestingDiscount = async () => {
            console.log("fetcinh vesting")
            try {
                const discount = await vaultContract?.vestingDiscount()
                setVestingDiscount(discount)
            }
            catch (error: any) {
                toast.error(error.message)
                return null
            }
        }

        if (account) {
            fetchVestingDiscount()
        }
    }, [account, library, chainId])

    if (!vestingDiscount) {
        return null
    }

    return vestingDiscount

}

export const useCreateLock = () => {
    const { library, account, chainId } = useActiveWeb3React()
    const vaultContract = getLpVaultContract(chainId, library, account)

    const handleCreateLock = useCallback(async (tokenAddress: string, withdrawalAddress: string, amount: string, unlockTime: number, fees: string | null, purpose: number) => {
        try {
            let _tokenAddress = isAddress(tokenAddress)
            let _withdrawalAddress = isAddress(withdrawalAddress)
            if (!library || !account || !chainId || !vaultContract || !_tokenAddress || !_withdrawalAddress || !fees || purpose < 0) return false
            const tx = await createLock(vaultContract, tokenAddress, _withdrawalAddress, amount, unlockTime, fees, purpose)
            return tx
        } catch (e: any) {
            toast.error(e.data.message || e.message)
            return false
        }
    }, [account, chainId, vaultContract, library])

    return { onHandleLock: handleCreateLock }
}

export const useCreateMultipleLock = () => {
    const { library, account, chainId } = useActiveWeb3React()
    const vaultContract = getLpVaultContract(chainId, library, account)

    const handleCreateMultipleLock = useCallback(async (tokenAddress: string, withdrawalAddress: string, amount: string[], unlockTime: number[], fees: string | null, purpose: number) => {
        try {
            let _tokenAddress = isAddress(tokenAddress)
            let _withdrawalAddress = isAddress(withdrawalAddress)
            if (!library || !account || !chainId || !vaultContract || !_tokenAddress || !_withdrawalAddress || !fees || purpose < 0) return false
            const tx = await createMultipleLock(vaultContract, tokenAddress, _withdrawalAddress, amount, unlockTime, fees, purpose)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId, vaultContract, library])

    return { onHandleMultipleLock: handleCreateMultipleLock }

}

export const useWithdrawLock = () => {
    const { library, account, chainId } = useActiveWeb3React()
    const vaultContract = getLpVaultContract(chainId, library, account)

    const handleWithdrawLock = useCallback(async (depositId: string) => {
        try {
            if (!library || !account || !chainId || !vaultContract) return false
            const tx = await withdrawLock(vaultContract, depositId)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId, vaultContract, library])

    return { onWithdrawLock: handleWithdrawLock }
}
