import React, { FunctionComponent } from "react";
import "./PageHeader.scss";

type PageHeaderProps = {
    title: string
}

const PageHeader: FunctionComponent<PageHeaderProps> = ({title}) => {
    return (
        <>
            <div className='page-heading page-title-left-grill mb-4'>
                <h3 className='page-sub-title mb-0'>{title}<span className='brand-color'>.</span></h3>
                <h1 className='page-title page-title-right-grill mb-0'>
                    {title}<span className='brand-color'>.</span>
                </h1>
            </div>
        </>
    );
};

export default PageHeader;
