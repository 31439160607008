import styled from "@emotion/styled";
import { Form } from "react-bootstrap";
    
type RangeSliderProps = {
    type: string;
    custom: boolean;
    value: number;
};

const RangeSlider = styled(Form.Control)<RangeSliderProps>((props) => ({
    height: "7px",
    "::-webkit-slider-runnable-track": {
        background: `linear-gradient(to right, #27debf 0%, #27debf ${props.value}%, #343644 ${props.value}%, #343644 100%)`,
        WebkitAppearance: "none",
        border: "solid 1px #606060",
    },
    "::-webkit-slider-thumb" : {
        backgroundColor: "#2ebba3",
        border: "solid 3px #27debf",
    
        height: "14px",
        width: "14px",
        borderRadius: "50%",
        WebkitAppearance: "none",
    }
}));

export default RangeSlider;