import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './vault-landing.scss';
import Typewriter from 'typewriter-effect';

import ImgHero from './images/hero-img.png'

import ImgIconTokenVesting from './images/icon-token-vesting.png'
import ImgIconLpLock from './images/icon-lp-lock.png'
import ImgIconInvDash from './images/icon-inv-dash.png'

import ImgHeroAuditedByQuill from './images/audited-by-quillhash-white.png';
import ImgHeroAuditedByCertik from './images/audited-by-certik.svg';
import ImgHeroAuditedByImmune from './images/audited-by-immunebytes.png';

import ImgChainEth from './images/chain/chain-eth.png'
import ImgChainBsc from './images/chain/chain-bsc.png'
import ImgChainPoly from './images/chain/chain-poly.png'
import ImgChainArb from './images/chain/chain-arb.png'
import ImgChainOp from './images/chain/chain-op.png'
import ImgChainAvax from './images/chain/chain-avax.svg'
import ImgChainKcc from './images/chain/chain-kcc.png'
import ImgChainOkx from './images/chain/chain-okx.svg'

import ImgTokenVesting from './images/token-vesting.png'
import ImgLpLock from './images/lp-lock.png'
import ImgInvDash from './images/inv-dash.png'

import ImgIconTrue from './images/true.png'
import ImgIconFalse from './images/false.png'

import ImgTrustAriva from './images/trust-ariva.png'
import ImgTrustAvoteo from './images/trust-avoteo.png'
import ImgTrustCharity from './images/trust-charity.svg'
import ImgTrustDexsport from './images/trust-dexsport.svg'
import ImgTrustDopewarz from './images/trust-dopewarz.png'

import ImgTrustDrife from './images/trust-drife.png'
import ImgTrustEcocredit from './images/trust-ecocredit.svg'
import ImgTrustMaticLaunch from './images/trust-maticlaunch.svg'
import ImgTrustUnix from './images/trust-unixgaming.svg'
import ImgTrustZenfuse from './images/trust-zenfuse.svg'

const VaultLanding = () => {
    return (
        <>
            <Container fluid className="brand-bgcolor-shadow-black pl-0 pr-0 vesting-landing-page">
                <div className='brand-bg-img'>
                    <div className='hero-content d-flex align-items-center'>
                        <Row className="hero-row">
                            <Col className="hero-title" lg={6} sm={6}>
                                {/* <h1 className="brand-color mb-1 display-3 font-bold">
                                    Blockius
                                </h1> */}
                                <h1 className="brand-color-white mb-0 font-bold sub-title-typing d-flex flex-row">
                                    <span className='mr-2'>Multichain</span>
                                    <Typewriter
                                        options={{
                                            strings: ['Escrow', 'Vesting', 'Locker'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                                </h1>
                                <h3 className="brand-color mb-2 font-bold sub-title">
                                    Platform for Web 3.0 Ecosystem
                                </h3>
                                <p className="brand-color-grey mb-3">
                                    The fastest and effortless way to automate token distribution, liquidity locks and track vesting schedules on blockchains.
                                </p>
                                <Row className='mb-4'>
                                    <Col>
                                        <a target="_blank" href="https://blockius.io/vault?type=vesting" className='mr-1'><button className="btn btn-brand mr-3 font-bold">TOKEN LOCK</button></a>
                                        <a target="_blank" href="https://blockius.io/vault?type=liquidity" className='mr-1'><button className="btn btn-brand-border mr-3 font-bold">LP LOCK</button></a>
                                        {/* <a target="_blank" href="https://coinmarketcap.com/currencies/blockpad/" className='mr-3'><img src={LogoCMC} width={31} /></a>
                                        <a target="_blank" href="https://www.coingecko.com/en/coins/blockius" className='mr-3'><img src={LogoGecko} width={31} /></a>
                                        <a target="_blank" href="https://www.dextools.io/app/bsc/pair-explorer/0x590acb1c067db3782021bf36edfa8845d5db132e" className='mr-2'><img src={LogoDexTools} width={28} /></a> */}
                                        {/* <a target="_blank" href="https://blockius.s3.amazonaws.com/pitchdeck.pdf"><button className="btn btn btn-brand-border mr-3"> Pitch Deck </button></a> */}
                                        {/* <a href={require('./docs/pitchdeck.pdf').default}><button className="btn btn-brand mr-3"> Pitch Deck </button></a> */}
                                        {/* <a href="https://theblockpad.docsend.com/view/56qxv5s2s7hzj87i"><button className="btn btn-brand-border"> One Pager </button></a> */}
                                    </Col>
                                </Row>
                                <div className='audited-by-container'>
                                    {/* <Col xs={3} className='text-right'> */}
                                    <h6 className='mb-0 mr-2 audit-by-title'>Audited By : </h6>
                                    {/* </Col>
                                    <Col> */}
                                    <div>
                                        <a target="_blank" className="audit-logo" href="https://www.certik.com/projects/blockius"><img className='img-fluid mr-2 certik-audit-logo' src={ImgHeroAuditedByCertik} width={120} /></a>
                                        <a target="_blank" className="audit-logo" href="https://github.com/Quillhash/Audit_Reports/blob/master/BPADVault%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf"><img className='img-fluid ml-2 mr-2' src={ImgHeroAuditedByQuill} width={140} /></a>
                                        <a target="_blank" className="audit-logo" href="https://github.com/ImmuneBytes/Smart-Contract-Audit-Reports/blob/main/BlockPad/BlockPad%20-%20Final%20Audit%20Report%20.pdf"><img className='img-fluid ml-2 immune-logo' src={ImgHeroAuditedByImmune} width={140} /></a>
                                    </div>
                                    {/* </Col> */}
                                </div>
                                {/* <img src={ImgHeroAuditedByQuill} width={150}/> */}
                                {/* <Row>
                                <Col className="mt-3">
                                    <a href="https://theblockpad.typeform.com/to/wb3DqaTm" target="_blank" className="public-sale-link">Fill Private Sale Interest Form &#62;&#62;</a>
                                </Col>
                            </Row> */}
                            </Col>
                            <Col className="brand-img-conatine text-right align-items-center" lg={6} sm={6}>
                                <img alt="hero vesting" className="img-fluid hero-img" src={ImgHero} />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="background-wrapper">
                    <div className='media-section section-margin'>
                        {/* <div className="section-header">
                            <h6 className="section-sub-title mb-0">BLOCKPAD FAMILY<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Backers & Partners<span className="brand-color">.</span></h1>
                        </div> */}
                        <div className="content-padding d-flex align-items-center media-section-flex">
                            <div className="title-container d-flex align-items-center">
                                <h5 className="media-title mb-0 text-nowrap">INTEGRATED WITH:</h5>
                            </div>
                            <Row className="chain-logo-container text-center align-items-center justify-content-around">
                                <Col className="media-logo py-1 px-2" lg={1} xs={3}>
                                    <img className="img-fluid mobile-small" alt="Ethereum" src={ImgChainEth} height="55px" />
                                </Col>
                                <Col className="media-logo py-2 px-2" lg={1} xs={3}>
                                    <img className="img-fluid mobile-small" alt="BSC" src={ImgChainBsc} height="55px" />
                                </Col>
                                <Col className="media-logo py-2 px-2" lg={1} xs={3}>
                                    <img className="img-fluid mobile-small" alt="Polygon" src={ImgChainPoly} height="55px" />
                                </Col>
                                <Col className="media-logo py-2 px-2" lg={1} xs={3}>
                                    <img className="img-fluid mobile-small" alt="Arbitrum" src={ImgChainArb} height="55px" />
                                </Col>
                                <Col className="media-logo py-2 px-2" lg={1} xs={3}>
                                    <img className="img-fluid mobile-small" alt="Optimism" src={ImgChainOp} height="55px" />
                                </Col>
                                <Col className="media-logo py-2 px-2" lg={1} xs={3}>
                                    <img className="img-fluid mobile-small" alt="Avax" src={ImgChainAvax} height="55px" />
                                </Col>
                                <Col className="media-logo py-2 px-2" lg={1} xs={3}>
                                    <img className="img-fluid mobile-small" alt="KCC" src={ImgChainKcc} height="45px" />
                                </Col>
                                <Col className="media-logo py-2 px-2" lg={1} xs={3}>
                                    <img className="img-fluid mobile-small desktop-large" alt="OKX" src={ImgChainOkx} height="25px" />
                                </Col>
                                {/* <Col className="media-logo py-2 px-2" lg={2} xs={6}>
                                    <img className="img-fluid " alt="market watch" src={ImgMediaDJ} width="200" />
                                </Col> */}
                            </Row>
                        </div>
                    </div>
                    <div className='type-of-vault-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Blockius Vault<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Blockius Vault<span className="brand-color">.</span></h1>
                        </div>
                        <div className='content-padding d-flex flex-column align-items-center'>
                            <Row className='card-container'>
                                <Col lg={4} sm={12}>
                                    <div className='vault-card'>
                                        <img className='text-center mb-3' src={ImgIconTokenVesting} height="60px"></img>
                                        <h5 className='brand-color vault-card-title font-bold'>Token Vesting</h5>
                                        <p className='vault-card-text'>Highly Customizable Escrow For Tokenomics</p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={12}>
                                    <div className='vault-card'>
                                        <img className='text-center mb-3' src={ImgIconLpLock} height="60px"></img>
                                        <h5 className='brand-color vault-card-title font-bold'>Liquidity Locking</h5>
                                        <p className='vault-card-text'>Tailor-made Solution For Locking LP Tokens</p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={12}>
                                    <div className='vault-card mb-0'>
                                        <img className='text-center mb-3' src={ImgIconInvDash} height="60px"></img>
                                        <h5 className='brand-color vault-card-title font-bold'>Investor Dashboard</h5>
                                        <p className='vault-card-text'>Intuitive Claim Dashboard For Early Stage Investors</p>
                                    </div>
                                </Col>
                            </Row>
                            {/* <div className='intergrated-chain d-flex flex-column align-items-center'>
                                <h4 className='font-bold'>INTEGRATED WITH:</h4>
                                <div className='logo-container'>
                                    <img src={ImgChainEth} height="55px" className='chain-icon'></img>
                                    <img src={ImgChainBsc} height="55px" className='chain-icon'></img>
                                    <img src={ImgChainPoly} height="55px" className='chain-icon'></img>
                                    <img src={ImgChainArb} height="55px" className='chain-icon'></img>
                                    <img src={ImgChainOp} height="55px" className='chain-icon'></img>
                                    <img src={ImgChainAvax} height="55px" className='chain-icon'></img>
                                    <img src={ImgChainKcc} height="50px" className='chain-icon'></img>
                                    <img src={ImgChainOkx} height="40px" className='chain-icon'></img>
                                </div>
                            </div> */}
                        </div>
                    </div>


                    <div className='offering-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">What we offer<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-right-grill text-center pt-5 font-bold">What We Offer<span className="brand-color">.</span></h1>
                        </div>
                        <div className='content-padding d-flex flex-column align-items-center'>
                            <Row className='offering-card-container'>
                                <Col className='item' lg={6} xs={12}>
                                    <div className='offering-card-container-1'>
                                        <div className='offering-card'>
                                            <div className='d-flex align-items-center mb-2'>
                                                <h4 className='font-bold brand-color mr-4 mb-0'>To Projects</h4>
                                                <div className='gradient-line'></div>
                                            </div>
                                            <p className='brand-color-white'>Disrupting projects on various blockchains are provided easily deployable token vesting and LP locking solutions</p>
                                            <h4 className='font-bold brand-color top-bottom-border'>Our value propositions:</h4>
                                            <ul className='propositions-list'>
                                                <li>Ease of scheduling token distribution to investors</li>
                                                <li>Peace of mind by few-clicks deployment</li>
                                                <li>Showcase trust by making locks visible</li>
                                                <li>Route developer focus elsewhere</li>
                                                <li>Saves time and audit finance</li>
                                            </ul>
                                        </div>
                                    </div>

                                </Col>
                                <Col className='item mb-0' lg={6} xs={12}>
                                    <div className='offering-card-container-2'>
                                        <div className='offering-card'>
                                            <div className='d-flex align-items-center mb-2'>
                                                <h4 className='font-bold brand-color mr-4 mb-0'>To Investors</h4>
                                                <div className='gradient-line'></div>
                                            </div>
                                            <p className='brand-color-white'>An intuitive platform for claiming invested tokens while zeroing rug chances occurred by early team/LP token sell-off</p>
                                            <h4 className='font-bold brand-color top-bottom-border'>Our value propositions:</h4>
                                            <ul className='propositions-list'>
                                                <li>Adequate platform for claiming invested tokens </li>
                                                <li>No errors guaranteed while token distribution</li>
                                                <li>Assurance of team not selling-off tokens</li>
                                                <li>Reliable continuity of circulating supply</li>
                                                <li>Visibility of locked liquidity value</li>
                                            </ul>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className='token-vesting-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Token Vesting<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Token Vesting<span className="brand-color">.</span></h1>
                        </div>
                        <div className='content-padding d-flex flex-column align-items-center'>
                            <Row className='align-items-center'>
                                <Col lg={5} xs={12}>
                                    <img className='img-fluid' src={ImgTokenVesting}></img>
                                </Col>
                                <Col lg={7} xs={12}>
                                    <div className='description'>
                                        <ul className='description-list'>
                                            <li>Intuitive user interface to deploy vesting contracts </li>
                                            <li>Highly customizable vesting schedules for tokenomics</li>
                                            <li>Singular locks, linear vesting, cliffed vesting support</li>
                                            <li>Simplified user interface for claiming unvested tokens</li>
                                            <li>Deployed locks exhibition to establish community trust</li>
                                        </ul>
                                        <a target="_blank" href="https://blockius.io/vault?type=vesting" className='mr-1 d-flex align-items-center justify-content-center text-decoration-none'>
                                            <button className="btn btn-brand mr-3 ml-5 font-bold">CREATE TOKEN LOCK</button>
                                            <div className='gradient-line'></div>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className='lp-lock-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Liquidity Locking<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-right-grill text-center pt-5 font-bold">Liquidity Locking<span className="brand-color">.</span></h1>
                        </div>
                        <div className='content-padding d-flex flex-column align-items-center'>
                            <Row className='align-items-center flex-sm-column-reverse-custom'>
                                <Col lg={7} xs={12}>
                                    <div className='description'>
                                        <ul className='description-list'>
                                            <li>Intuitive user interface to deploy LP lock contract </li>
                                            <li>Singular locks, linear vesting & cliffed vesting support</li>
                                            <li>Simplified user interface for claiming released tokens</li>
                                            <li>Deployed locks exhibition to establish community trust</li>
                                            <li>No prior technical knowledge is required</li>
                                        </ul>
                                        <a target="_blank" href="https://blockius.io/vault?type=liquidity" className='mr-1 d-flex align-items-center justify-content-center text-decoration-none'>
                                            <button className="btn btn-brand mr-3 ml-5 font-bold">CREATE LP LOCK</button>
                                            <div className='gradient-line'></div>
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={5} xs={12}>
                                    <img className='img-fluid' src={ImgLpLock}></img>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className='inv-dash-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Investor Dashboard<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Investor Dashboard<span className="brand-color">.</span></h1>
                        </div>
                        <div className='content-padding d-flex flex-column align-items-center'>
                            <Row className='align-items-center'>
                                <Col lg={5} xs={12}>
                                    <img className='img-fluid' src={ImgInvDash}></img>
                                </Col>
                                <Col lg={7} xs={12}>
                                    <div className='description'>
                                        <ul className='description-list'>
                                            <li>Effortless process for creating vesting for investors </li>
                                            <li>Single CSV upload feature for bulk addresses’ vesting</li>
                                            <li>Full flexibility in deploying timeframe based vesting</li>
                                            <li>Effortless accumulation of unclaimed tokens for users</li>
                                            <li>Simplified user interface for claiming released tokens</li>
                                        </ul>
                                        {/* <div className='d-flex align-item-centers justified-content-center'> */}
                                            <a target="_blank" href="mailto:info@blockius.io" className='mr-1 d-flex align-items-center justify-content-center text-decoration-none'>
                                                <button className="btn btn-brand mr-3 ml-5 font-bold">REQUEST DEMO</button>
                                                <div className='gradient-line'></div>
                                            </a>
                                        {/* </div> */}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className='comparison-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Comparison<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-right-grill text-center pt-5 font-bold">Comparison<span className="brand-color">.</span></h1>
                        </div>
                        <div className='content-padding d-flex flex-column align-items-center'>
                            <div className='table-container'>
                                {/* <table className="table">
                                    <thead>
                                        <tr>
                                            <th title="Field #1"></th>
                                            <th title="Field #2">Singular Locks</th>
                                            <th title="Field #3">Linear Vesting</th>
                                            <th title="Field #4">Cliffed Vesting</th>
                                            <th title="Field #5">Liquidity Locks</th>
                                            <th title="Field #6">Claim Dashboard</th>
                                            <th title="Field #7">White Label Platform</th>
                                            <th title="Field #8">Supported Blockchains</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td width="16%">Blockius</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td>ETH, BSC, Polygon, Arbitrum, Optimism, Avax, KCC, OKX</td>
                                        </tr>
                                        <tr>
                                            <td>UNCX Network</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td>ETH, ARB, BSC, AVAX, xDAI, Polygon, Dogechain</td>
                                        </tr>
                                        <tr>
                                            <td>Team Finance</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td>ETH, BSC, AVAX, Polygon, Cronos, Heco, Velas, Fantom, Kava, Conflux, Algorand, ETC, CasperLabs</td>
                                        </tr>
                                        <tr>
                                            <td>Unvest</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td>ETH, optimism, BSC, Polygon, Arbitrum, AVAX</td>
                                        </tr>
                                        <tr>
                                            <td>Mudra</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td>BSC</td>
                                        </tr>
                                        <tr>
                                            <td>Cryptexlock</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='13px'/></td>
                                            <td>BSC, Polygon, Gnosis, Fantom, Avax, Cronos, Aurora, Moonriver</td>
                                        </tr>
                                    </tbody>
                                </table> */}

                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th title=""></th>
                                            <th title="Blockius">Blockius</th>
                                            <th title="UNCX Network">UNCX Network</th>
                                            <th title="Team Finance">Team Finance</th>
                                            <th title="Unvest">Unvest</th>
                                            <th title="Mudra">Mudra</th>
                                            <th title="Cryptexlock">Cryptexlock</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td width="19%">Singular Locks</td>
                                            <td width="13.5%"><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td width="13.5%"><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td width="13.5%"><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td width="13.5%"><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td width="13.5%"><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td width="13.5%"><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                        </tr>
                                        <tr>
                                            <td>Linear Vesting</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                        </tr>
                                        <tr>
                                            <td>Cliffed Vesting</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                        </tr>
                                        <tr>
                                            <td>Liquidity Locks</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                        </tr>
                                        <tr>
                                            <td>Claim Dashboard</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                        </tr>
                                        <tr>
                                            <td>White Label Platform</td>
                                            <td><img src={ImgIconTrue} className='img-fluid' width='13px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                            <td><img src={ImgIconFalse} className='img-fluid' width='12px' /></td>
                                        </tr>
                                        <tr>
                                            <td>Supported Blockchains</td>
                                            <td>
                                                <div className="wrapper">
                                                    {/* <td>ETH, BSC, Polygon, Optimism, Arbitrum, Avax, KCC, OKX</td> */}
                                                    <img src={ImgChainEth} className='icon'></img>
                                                    <img src={ImgChainBsc} className='icon'></img>
                                                    <img src={ImgChainPoly} className='icon'></img>
                                                    <img src={ImgChainArb} className='icon'></img>
                                                    <div className="icon more-count">
                                                        <p className='mb-0'>+4</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wrapper">
                                                    {/* <td>ETH, BSC, Polygon, Arbitrum, AVAX, xDAI, Dogechain</td> */}
                                                    <img src={ImgChainEth} className='icon'></img>
                                                    <img src={ImgChainBsc} className='icon'></img>
                                                    <img src={ImgChainPoly} className='icon'></img>
                                                    <img src={ImgChainArb} className='icon'></img>
                                                    <div className="icon more-count">
                                                        <p className='mb-0'>+3</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wrapper">
                                                    {/* <td>ETH, BSC, Polygon, AVAX, Cronos, Heco, Velas, Fantom, Kava, Conflux, Algorand, ETC, CasperLabs</td> */}
                                                    <img src={ImgChainEth} className='icon'></img>
                                                    <img src={ImgChainBsc} className='icon'></img>
                                                    <img src={ImgChainPoly} className='icon'></img>
                                                    <img src={ImgChainAvax} className='icon'></img>
                                                    <div className="icon more-count">
                                                        <p className='mb-0'>+9</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wrapper">
                                                    {/* <td>ETH, BSC, Polygon, Optimism,  Arbitrum, AVAX</td> */}
                                                    <img src={ImgChainEth} className='icon'></img>
                                                    <img src={ImgChainBsc} className='icon'></img>
                                                    <img src={ImgChainPoly} className='icon'></img>
                                                    <img src={ImgChainArb} className='icon'></img>
                                                    <div className="icon more-count">
                                                        <p className='mb-0'>+2</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wrapper">
                                                    {/* <td>BSC</td> */}
                                                    <img src={ImgChainBsc} className='icon'></img>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wrapper">
                                                    {/* <td>BSC, Polygon, Avax, Gnosis, Fantom, Cronos, Aurora, Moonriver</td> */}
                                                    <img src={ImgChainBsc} className='icon'></img>
                                                    <img src={ImgChainPoly} className='icon'></img>
                                                    <img src={ImgChainAvax} className='icon'></img>
                                                    <div className="icon more-count">
                                                        <p className='mb-0'>+5</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className='trusted-section section-margin-trusted mb-0'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Trusted By<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Trusted By<span className="brand-color">.</span></h1>
                        </div>
                        <div className='content-padding d-flex flex-column align-items-center'>
                            <Row className='trust-logo-container-desktop text-center align-items-center justify-content-around mb-4'>
                                <Col lg={2} sm={6}><img className='img-fluid' src={ImgTrustUnix} width="105"></img></Col>
                                <Col lg={2} sm={6}><img className='img-fluid' src={ImgTrustEcocredit} width="370"></img></Col>
                                <Col lg={2} sm={6}><img className='img-fluid mr-3 ml-3' src={ImgTrustAriva} width="100"></img></Col>
                                <Col lg={2} sm={6}><img className='img-fluid' src={ImgTrustDexsport} width="130"></img></Col>
                                <Col lg={1} sm={6}><img className='img-fluid' src={ImgTrustDrife} width="50"></img></Col>
                            </Row>
                            <Row className='trust-logo-container-desktop text-center align-items-center justify-content-around'>
                                <Col lg={2} sm={6}><img className='img-fluid' src={ImgTrustMaticLaunch} width='100'></img></Col>
                                <Col lg={2} sm={6}><img className='img-fluid' src={ImgTrustAvoteo} width='350'></img></Col>
                                <Col lg={2} sm={6}><img className='img-fluid' src={ImgTrustZenfuse} width='150'></img></Col>
                                <Col lg={2} sm={6}><img className='img-fluid' src={ImgTrustDopewarz} width='140'></img></Col>
                                <Col lg={1} sm={6}><img className='img-fluid' src={ImgTrustCharity} width='45'></img></Col>
                            </Row>
                        </div>
                        <div className='content-padding d-flex flex-column align-items-center'>
                            <Row className='trust-logo-container-mobile text-center align-items-center justify-content-around mb-4'>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustUnix} width="105"></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustEcocredit} width="120"></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustMaticLaunch} width='100'></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustAvoteo} width='120'></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustAriva} width="100"></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustDexsport} width="130"></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustZenfuse} width='120'></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustDopewarz} width='140'></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustDrife} width="50"></img></Col>
                                <Col lg={6} xs={6}><img className='img-fluid mb-4' src={ImgTrustCharity} width='45'></img></Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default VaultLanding