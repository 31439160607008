import React, {FunctionComponent} from "react";
import "./DataCard.css";

type DataIconProps = {
    icon: string,
    value: string,
    name: string
}

const DataCard: FunctionComponent<DataIconProps> = ({icon, value, name}) => {
    return (
        <div className='data-card align-items-center d-flex'>
            <img
                className='data-icon'
                src={icon}
                alt='data-icon'
            />
            <div className='d-flex flex-column'>
                <h4 className='data-card-title m-0 mb-1'>{value}</h4>
                <p className='text-center data-card-caption m-0'>{name}</p>
            </div>
        </div>
    );
};

export default DataCard;