import { Contract } from '@ethersproject/contracts'
import ERC20_ABI from '../constants/abis/erc20.json'
import VAULT_ABI from '../constants/abis/vault.json'
import INVDASH_ABI from '../constants/abis/invDash.json'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { getContract } from './index'
import { getLpVaultAddress, getVestingVaultAddress} from './addressHelpers'

export function getTokenContract(tokenAddress?: string, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    // const { library, account } = useActiveWeb3React()
    if (!tokenAddress || !library || !account) return null
    return getContract(tokenAddress, ERC20_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getPairContract(pairAddress?: string, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    if (!pairAddress || !library || !account) return null
    return getContract(pairAddress, IUniswapV2PairABI, library ,withSignerIfPossible && account ? account : undefined)
}

// export function getVaultContract(chainId?: number, library?: any, account?: string | null, withSignerIfPossible = true ): Contract | null {
//     if (!chainId || !library || !account) return null
//     return getContract(getVaultAddress(chainId), VAULT_ABI, library ,withSignerIfPossible && account ? account : undefined)
// }

export function getLpVaultContract(chainId?: number, library?: any, account?: string | null, withSignerIfPossible = true ): Contract | null {
    if (!chainId || !library || !account) return null
    return getContract(getLpVaultAddress(chainId), VAULT_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getVestingVaultContract(chainId?: number, library?: any, account?: string | null, withSignerIfPossible = true ): Contract | null {
    if (!chainId || !library || !account) return null
    return getContract(getVestingVaultAddress(chainId), VAULT_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getInvDashContract(invDashAddress?: string, library?: any, account?: string | null, withSignerIfPossible = true ): Contract | null {
    if (!invDashAddress || !library || !account) return null
    return getContract(invDashAddress, INVDASH_ABI, library, withSignerIfPossible && account ? account : undefined)
}