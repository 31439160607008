import React from 'react'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
// import { Provider } from 'react-redux'
// import { ModalProvider } from '@pancakeswap-libs/uikit'
import { NetworkContextName } from './constants'
// import store from './state'
import getLibrary from './utils/getLibrary'
import { RefreshContextProvider } from './context/RefreshContext'
// import { ThemeContextProvider } from './ThemeContext'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
      <RefreshContextProvider>
        {children}
        {/* <Provider store={store}> */}
          {/* <ThemeContextProvider> */}
            {/* <ModalProvider></ModalProvider> */}
          {/* </ThemeContextProvider> */}
        {/* </Provider> */}
        </RefreshContextProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  )
}

export default Providers
