import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Border from './images/border.png';
import Padlock from "./images/liquidity-lock.png";
import Sandclock from "./images/vesting-schedule.png";
import Badge from "./images/reveal-locks.png";
import LiquidityIcon from "./images/liquidity-icon.svg";
import TokenIcon from "./images/token-icon.svg";
import TrustIcon from "./images/trust-icon.svg";
import DataCard from "../../components/DataCard";
import VaultTable from "../../components/VaultTable";
import "./vault.scss";

import data from "./temp.json";
import { getList } from '../../services/vault'
import PageHeader from "../../components/PageHeader";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";

const Vault = (props: any) => {
    const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [walletRequired, setWalletRequired] = useState(false);
    const [isOnlyMyLock, setIsOnlyMyLock] = useState(false);
    const { account } = useWeb3React();
    const [totalLocked, setTotalLocked] = useState(0);

    useEffect(() => {
        // var formattedList = []
        let TVL = 0
        getList().then((res) => {
            console.log(res)
            let vaultList = res.data
            vaultList.forEach((vault: any) => {
                vault.tokenLocked = 0
                vault.chain = ""
                vault.nextReleaseTime = 100000000000000
                if (vault.lockitems) {
                    vault.lockitems.forEach((lock: any) => {
                        if (!lock) return
                        vault.tokenLocked += lock.amount
                        vault.chain = lock.chain
                        if (vault.nextReleaseTime > lock.unlockTimestamp && lock.isWithdrawn === false && (lock.unlockTimestamp > Date.now() / 1000)) {
                            vault.nextReleaseTime = lock.unlockTimestamp
                        }
                    })
                }
                let price = vault.priceFeed && vault.priceFeed[0] && vault.priceFeed[0].usdPrice
                vault.totalValueLocked = '$' + (vault.tokenLocked * price ?? 0).toFixed(2)
                TVL += (vault.tokenLocked * price ?? 0)
            });
            setList(vaultList)
            setFilteredList(vaultList)
            setTotalLocked(TVL)
        })

    }, [])

    const filterList = function (val: any) {
        setFilteredList(
            list.filter((vault: any) => {
                if (vault.address.toLocaleLowerCase().includes(val.toLocaleLowerCase()) ||
                    vault.symbol.toLocaleLowerCase().includes(val.toLocaleLowerCase()) ||
                    vault.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())
                ) {
                    return true
                }
                // let lock = vault.lockitems.some((lockItem: any) => {
                //     return Boolean(lockItem.withdrawal.toLocaleLowerCase())
                // })
                // return lock
            })
        )
    }

    const filterMyLocks = function () {
        if (isOnlyMyLock) {
            setWalletRequired(false)
            setFilteredList(list)
        } else {
            if (!account) setWalletRequired(true)
            setFilteredList(
                list.filter((vault: any) => {
                    let lock = vault.lockitems.some((lockItem: any) => {
                        return Boolean(lockItem.withdrawal.toLocaleLowerCase() == account?.toLocaleLowerCase())
                    })
                    return lock
                })
            )
        }
        setIsOnlyMyLock(!isOnlyMyLock)

    }

    return (
        <div className="vault-list-container">
            <Container
                fluid
                className='brand-bgcolor-shadow-black pl-0 pr-0 vault-background pb-5'
            >
                <PageHeader title='Blockius Vault' />
                <p className='page-caption text-center mb-0'>
                    Making Liquidity Locks and Team Vesting Easier.
                </p>
                <div className='d-flex justify-content-center'>
                    <h5 className='brand-color-white font-bold'>
                        FOR IDOS AND COMMUNITY
                    </h5>
                </div>
                <main className='page-main'>
                    <Row className='justify-space-evenly data-card-holder m-0 p-3'>
                        <DataCard
                            icon={TokenIcon}
                            value={`$${totalLocked.toLocaleString()}`}
                            name={"Locked Token Value"}
                        />
                        <DataCard
                            icon={LiquidityIcon}
                            value={"$400,000+"}
                            name={"Locked Liquidity Value"}
                        />
                        <DataCard
                            icon={TrustIcon}
                            value={String(list.length)}
                            name={"Projects Trusted Us"}
                        />
                    </Row>
                    <Row className='justify-content-center text-center m-0 vault-btn'>
                        {/* <Col lg={4}></Col> */}
                        <Col lg={4} md={6} sm={6} xs={6} className="mt-3 d-flex justify-content-end btn-center">
                            <Link
                                to={
                                    new URLSearchParams(props.location.search).get("type") === "liquidity"
                                        ? "/vault/create-lp-lock"
                                        : new URLSearchParams(props.location.search).get("type") === "investor-vesting"
                                            ? "/vault/create-investor-vesting"
                                            : "/vault/create-vesting"
                                }
                                className='btn btn-brand m-0'
                            >
                                {new URLSearchParams(props.location.search).get("type") === "liquidity"
                                    ? "CREATE LIQUIDITY LOCK"
                                    : new URLSearchParams(props.location.search).get("type") === "investor-vesting"
                                        ? "CREATE AN INVESTOR VESTING"
                                        : "CREATE A VESTING"}
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="mt-3 d-flex justify-content-start btn-center">
                            <button className="btn btn-brand-border font-bold" onClick={filterMyLocks}>
                                {isOnlyMyLock && ('VIEW ALL LOCKS')} {!isOnlyMyLock && ('TRACK MY LOCKS')}
                            </button>
                        </Col>]
                        {/* <Col lg={4}></Col> */}
                    </Row>
                    <Row className='m-0 px-3'>
                        <Col className='d-flex flex-column align-items-center search-card'>
                            <h4 className='search-title'>
                                Search A Smart Lock Address
                            </h4>
                            <Form.Control
                                className='search-input'
                                type='text'
                                placeholder='Search a smart lock by name/contract address'
                                onChange={({ target: { value } }) => {
                                    filterList(value)
                                }}
                            />
                        </Col>
                    </Row>
                    {walletRequired && (<div className="error-message-container text-center">
                        <h2>Please connect your wallet to track your token in vault</h2>
                    </div>)}
                    {!walletRequired && filteredList.length === 0 && (<div className="error-message-container text-center">
                        <h2>No locks found</h2>
                    </div>)}
                    {!walletRequired && filteredList.length > 0 && (<Row className='m-0'>
                        <Col className='mt-3'>
                            <VaultTable data={filteredList} />
                        </Col>
                    </Row>)}
                    {/* <Row className='justify-content-center m-0'>
                        <Col
                            lg={4}
                            xs={12}
                            className='d-flex align-items-center flex-column vault-card borderline-right'
                        >
                            <img
                                className='vault-icon'
                                src={Sandclock}
                                alt='sandclock'
                            />
                            <h4 className='vault-card-title'>
                                Token Vesting Schedule
                            </h4>
                            <p className='px-5 text-center vault-card-caption'>
                                Create a customized vesting schedule either for
                                team or advisory tokens
                            </p>
                        </Col>
                        <Col
                            lg={4}
                            xs={12}
                            className='d-flex align-items-center flex-column vault-card borderline-right'
                        >
                            <img
                                className='vault-icon'
                                src={Padlock}
                                alt='padlock'
                            />
                            <h4 className='vault-card-title'>
                                Smart Liquidity Lock
                            </h4>
                            <p className='px-5 text-center vault-card-caption'>
                                Lock your LP token minted from DEXs with a
                                certain release date (timeframe)
                            </p>
                        </Col>
                        <Col
                            lg={4}
                            xs={12}
                            className='d-flex align-items-center flex-column vault-card'
                        >
                            <img
                                className='vault-icon'
                                src={Badge}
                                alt='badge'
                            />
                            <h4 className='vault-card-title'>
                                Reveal Your Locks Publicly
                            </h4>
                            <p className='px-5 text-center vault-card-caption'>
                                Display your locks confidently with our
                                intuitive UI with countdown timer
                            </p>
                        </Col>
                    </Row> */}
                </main>
                <Row className="feature-container pt-4 content-padding">
                    <Col lg={4}>
                        <div className="feature-card text-center">
                            <img className="img-fluid" src={Sandclock} alt='sandclock' />
                            <div className="border-container">
                                <img className="img-fluid border-img" src={Border} alt='sandlock' />
                            </div>
                            <div className="feature-card-body">
                                <h5 className="brand-color-white font-bold">Token Vesting Schedule</h5>
                                <p className="brand-color-grey">
                                    Create a customized vesting schedule either for
                                    team or advisory tokens
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="feature-card text-center">
                            <img className="img-fluid" src={Padlock} alt='padlock' />
                            <div className="border-container">
                                <img className="img-fluid border-img" src={Border} alt='sandlock' />
                            </div>
                            <div className="feature-card-body">
                                <h5 className="brand-color-white font-bold">Smart Liquidity Lock</h5>
                                <p className="brand-color-grey">
                                    Lock your LP token minted from DEXs with a
                                    certain release date (timeframe)
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="feature-card text-center">
                            <img className="img-fluid" src={Badge} alt='badge' />
                            <div className="border-container">
                                <img className="img-fluid border-img" src={Border} alt='sandlock' />
                            </div>
                            <div className="feature-card-body">
                                <h5 className="brand-color-white font-bold">Reveal Your Locks Publicly</h5>
                                <p className="brand-color-grey">
                                    Display your locks confidently with our
                                    intuitive UI with countdown timer
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Vault;
