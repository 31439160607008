import addresses from '../constants/contract'

const getAddress = (address: any, chainId: any): string => {
  return address[chainId]
}

// export const getVaultAddress = (chainId: number) => {
//   return getAddress(addresses.vault, chainId)
// }

export const getLpVaultAddress = (chainId: number) => {
  return getAddress(addresses.lpVault, chainId)
}

export const getVestingVaultAddress = (chainId: number) => {
  return getAddress(addresses.vestingVault, chainId)
}
