import React from 'react'
import { Modal } from 'react-bootstrap'
import { useWeb3React } from '@web3-react/core'
import './account-details.css'
import { getChainDataById, getExplorerName, getExplorerUrl } from '../../utils'

function AccountDetails(props: any) {
    const { account, deactivate, chainId } = useWeb3React()
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="brand-color-white"
        >
            <Modal.Header closeButton className="brand-bgcolor-shadow-black">
                <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                    <h5 className='mb-0'>Connected Wallet [{getChainDataById(chainId)?.chainName}]</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="brand-bgcolor-shadow-black">
                <h6 className="text-center text-truncate account-address">Address : {account}</h6>
                {/* <h6 className="text-truncate text-left account-address">Connected Chain : {getChainDataById(chainId)?.chainName}</h6> */}
                {window.localStorage.getItem('connectorId') === 'walletconnect' && <h6 className="text-center small information-note">🧭 Please log out and log in again to change the connected chain</h6>}
                <div className="d-flex justify-content-center mt-3 mb-3">
                    <button className='btn btn-logout mr-4' onClick={() => {
                        deactivate()
                        window.localStorage.removeItem('connectorId');
                        window.localStorage.removeItem('walletconnect');
                        window.localStorage.removeItem('userSelectedChainId')
                        props.onHide()
                        window.location.reload()
                    }}>Logout</button>
                    <a className="btn btn-view-on" target="_blank" href={getExplorerUrl(chainId) + 'address/' + account} >{"View on " + getExplorerName(chainId)}</a>
                    {/* <button className='btn btn-view-on mr-5'>View</button> */}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AccountDetails