import React, { Suspense, useState } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import BigNumber from 'bignumber.js'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

import Header from '../components/Header'
import Footer from '../components/Footer'
import Web3ReactManager from '../components/Web3ReactManager';


import Home from './Home'
import RedirectToHome from './Home/redirect'
import Pools from './Pools'
import Staking from './Staking'
import Vault from './Vault'
import VaultLanding from './Vault-landing'
// import InvDash from './InvDash'
import Voting from './Voting'
import ComingSoon from './Voting_old'
import PoolPage from './PoolPage';
import CreateVestingLock from './create-lock-vesting';
import CreateLpLock from './create-lock-lp-token';
import LockDetails from './lock-details-vesting';
import LpLockDetails from './lock-details-lp-token';
// import InvestorLockDetails from './lock-details-investor-vesting';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PoolPageDetails from './PoolPage/PoolPageDetails';

import { Collapse } from 'react-bootstrap';
import ScrollToTop from '../hooks/ScrollToTop';

// import 'react- react-toastify/dist/ReactToastify.css';

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

toast.configure(
  {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  }
)

function App() {
  const [notiHeader, setNotiHeader] = useState(sessionStorage.getItem("notiHeader") === 'true' ? false : true)
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Web3ReactManager>
          <>
            {/* <Collapse mountOnEnter={true} in={notiHeader} timeout={1000}>
              <div className="airdrop-header">
                <div className="airdrop-header-content">
                  <a href="https://blockius.medium.com/rebranding-blockpad-as-blockius-entering-a-new-era-2d4d2ebe4032" target='_blank'>BlockPad is now rebranded to Blockius!</a></div>
                <div className="airdrop-header-close" onClick={() => {
                  setNotiHeader(false)
                  sessionStorage.setItem("notiHeader", "true")
                }}>X</div>
              </div>
            </Collapse> */}
            <ScrollToTop />
            <Header></Header>
            <div className="body-content">
              <Switch>
                <Route exact strict path="/" component={Home} />
                {/* <Route exact strict path="/pool-details/:address" children={<ComingSoon />} />
                <Route exact strict path="/pools" component={ComingSoon} /> */}
                {/* <Route exact strict path="/staking" component={Staking} /> */}
                <Route exact strict path="/platform" component={VaultLanding} />
                <Route exact strict path="/vault" component={Vault} />
                {/* <Route exact strict path="/claim" component={InvDash} /> */}
                <Route exact strict path="/vault/create-vesting" component={CreateVestingLock} />
                <Route exact strict path="/vault/create-lp-lock" component={CreateLpLock} />
                <Route exact strict path="/vault-vesting/:tokenAddress" component={LockDetails} />
                <Route exact strict path="/vault-lp-vault/:tokenAddress" component={LpLockDetails} />
                {/* <Route exact strict path="/claim/:tokenAddress" component={InvestorLockDetails} /> */}
                {/* <Route exact strict path="/voting" component={Voting} /> */}
                <Route component={RedirectToHome} />
              </Switch>
            </div>
            <Footer></Footer>
          </>
        </Web3ReactManager>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
