import React from 'react'
import './footer.css'

const Footer = () => (
    <footer className="footer mt-auto pt-5 pb-3">
        <div className="container text-center">
            <img alt="logo" className="fooetr-logo mb-4" src="../images/logo.png"></img>
            <div className="social-icons d-flex justify-content-center mb-4">
                {/* <a href="/home">
                    <div className="social-item d-flex flex-column align-items-center mr-3">
                        <img alt="social fb" className="social-icon mb-1" src="images/social-fb.svg"></img>
                        <small className="brand-color-grey">Facebook</small>
                    </div>
                </a> */}
                <a href="https://twitter.com/blockius_io" target='_blank'>
                    <div className="social-item d-flex flex-column align-items-center mr-3">
                        <img alt="social twitter" className="social-icon mb-1" src="../images/social-twitter.svg"></img>
                        <small className="brand-color-grey">Twitter</small>
                    </div>
                </a>
                <a href="https://telegram.me/blockius_io" target='_blank'>
                    <div className="social-item d-flex flex-column align-items-center mr-3">
                        <img alt="social telegram" className="social-icon mb-1" src="../images/social-telegram.svg"></img>
                        <small className="brand-color-grey">Telegram</small>
                    </div>
                </a>
                <a href="https://blockius.medium.com/" target='_blank'>
                    <div className="social-item d-flex flex-column align-items-center mr-3">
                        <img alt="social medium" className="social-icon mb-1" src="../images/social-medium.svg"></img>
                        <small className="brand-color-grey">Medium</small>
                    </div>
                </a>
                <a href="/" target='_blank'>
                    <div className="social-item d-flex flex-column align-items-center mr-3">
                        <img alt="social github" className="social-icon mb-1" src="../images/social-github.svg"></img>
                        <small className="brand-color-grey">Github</small>
                    </div>
                </a>
                <a href="mailto:info@blockius.io">
                    <div className="social-item d-flex flex-column align-items-center">
                        <img alt="social email" className="social-icon mb-1" src="../images/social-email.svg"></img>
                        <small className="brand-color-grey">Email</small>
                    </div>
                </a>
            </div>
            <div className="information-section d-flex justify-content-center mb-3">
                <a className="d-flex btn-apply-for-ido align-items-center" href="https://blockius.typeform.com/to/AcD9kxak" target='_blank'>
                    <div className="btn-apply-for-ido-left d-flex align-items-center">
                        <img alt="blockius" src="../images/blockPad_rocket.svg" width="23" className="m-auto"/>
                    </div>
                    <h5 className="mb-0 py-2 font-bold btn-apply-for-ido-right">APPLY IDO</h5>
                </a>
            </div>
            <div className="divider mb-3"></div>
            <div className="copyright-section d-flex justify-content-between">
                <small className="brand-color-white"><a className="footer-link" href="https://blockius.io">Blockius</a> © 2022 | All Rights reserved</small>
                <div className="blockchain-info">
                    <a href="https://bscscan.com/address/0xd5e950837Ad48D08baD2f87bFcF8eD7167bB44BC" target='_blank'><img alt="bscscan" className="blockchain-bscscan-icon mr-2" src="../images/bscscan.svg"></img></a>
                    <a href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xd5e950837Ad48D08baD2f87bFcF8eD7167bB44BC" target='_blank'><img alt="pancakeswap" className="blockchain-pancakeswap-icon" src="../images/pancakeswap.svg"></img></a>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
