import { InjectedConnector } from '@web3-react/injected-connector';
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const isProd = true;

let devSupportedChainIds = [3, 97, 80001, 322, 43113, 65, 420, 421613]
let prodSupportedChainIds = [1, 56, 137, 321, 43114, 66, 10, 42161]

let devRPC = {
    3: "https://ropsten.infura.io/v3/a97b482a0b974ef4a09f963f415d688a",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    80001: "https://rpc-mumbai.matic.today",
    322: "https://rpc-testnet.kcc.network",
    43113: "https://api.avax-test.network/ext/bc/C/rpc",
    65: "https://exchaintestrpc.okex.org",
    420: "https://goerli.optimism.io",
    421613: "https://goerli-rollup.arbitrum.io/rpc"
}

let prodRPC = {
    1: "https://mainnet.infura.io/v3/a97b482a0b974ef4a09f963f415d688a",
    56: "https://bsc-dataseed.binance.org/",
    137: "https://polygon-rpc.com/",
    321: "https://rpc-mainnet.kcc.network",
    43114: "https://api.avax.network/ext/bc/C/rpc",
    66: "https://exchainrpc.okex.org",
    10: "https://mainnet.optimism.io",
    42161: "https://arb1.arbitrum.io/rpc"
}

const prodChainDetails = [
    {
        title: "BSC",
        chainId: 56,
        icon: "../images/selectChainIcon/bsc.svg"
    },
    {
        title: "Polygon",
        chainId: 137,
        icon: "../images/selectChainIcon/polygon.svg"
    },
    {
        title: "AVAX",
        chainId: 43114,
        icon: "../images/selectChainIcon/avax.svg"
    },
    {
        title: "Ethereum",
        chainId: 1,
        icon: "../images/selectChainIcon/eth.svg"
    },
    {
        title: "KCC",
        chainId: 321,
        icon: "../images/selectChainIcon/kcc.svg"
    },
    {
        title: "OEC",
        chainId: 66,
        icon: "../images/selectChainIcon/oec.svg"
    },
    {
        title: "Optimism",
        chainId: 10,
        icon: "../images/selectChainIcon/optimism.svg"
    },
    {
        title: "Arbitrum",
        chainId: 42161,
        icon: "../images/selectChainIcon/arbitrum.svg"
    }
]

const devChainDetails = [
    {
        title: "Ropston",
        chainId: 3,
        icon: "../images/selectChainIcon/eth.svg"
    },
    {
        title: "BSC",
        chainId: 97,
        icon: "../images/selectChainIcon/bsc.svg"
    },
    {
        title: "Polygon",
        chainId: 80001,
        icon: "../images/selectChainIcon/polygon.svg"
    },
    {
        title: "AVAX",
        chainId: 43113,
        icon: "../images/selectChainIcon/avax.svg"
    },
    {
        title: "KCC",
        chainId: 322,
        icon: "../images/selectChainIcon/kcc.svg"
    },
    {
        title: "OEC",
        chainId: 65,
        icon: "../images/selectChainIcon/oec.svg"
    },
    {
        title: "Optimism",
        chainId: 420,
        icon: "../images/selectChainIcon/optimism.svg"
    },
    {
        title: "Arbitrum",
        chainId: 421613,
        icon: "../images/selectChainIcon/arbitrum.svg"
    }
]

export const injected = new InjectedConnector({
    supportedChainIds: isProd ? prodSupportedChainIds : devSupportedChainIds,
})

export const walletconnect: any = function (chainId: number, isTrustWallet = false) {
    return new WalletConnectConnector({
        // supportedChainIds: devSupportedChainIds,
        // preferredNetworkId: 56,
        // rpc: devRPC,
        // supportedChainIds: prodSupportedChainIds,
        rpc: isProd ? prodRPC : devRPC,
        chainId: chainId,
        qrcode: isTrustWallet ? undefined : true,
        qrcodeModalOptions: isTrustWallet ? { 
            mobileLinks: [
                "trust"
            ]
        } : undefined
    })
}

// export const walletconnect = 

export const chainSelectionDetails = isProd ? prodChainDetails : devChainDetails

export const gnosisSafe = new SafeAppConnector(); 
