const vestingVault = {
    1: '0x629fc9f02749284fd7694ff0169ff6a6801d97c2',
    56: '0x629fc9f02749284fd7694ff0169ff6a6801d97c2',
    66: '0x629fc9f02749284fd7694ff0169ff6a6801d97c2',
    137: '0x629fc9f02749284fd7694ff0169ff6a6801d97c2',
    321: '0x629fc9f02749284fd7694ff0169ff6a6801d97c2',
    43114: '0x629fc9f02749284fd7694ff0169ff6a6801d97c2',
    10: '0x629fc9f02749284fd7694ff0169ff6a6801d97c2',
    42161: '0x483c447B0C914B6e2F2C95b7b0E4a290D4cAD84d',

    3: '0x27C46ACb46f425A57BF568e2e729a02fdf72A7fa',
    97: '0x27C46ACb46f425A57BF568e2e729a02fdf72A7fa',
    65: '0x2ab24e3c90c70ee41b88931f3a1360a862289e5c',
    80001: '0x27C46ACb46f425A57BF568e2e729a02fdf72A7fa',
    322: '0x27C46ACb46f425A57BF568e2e729a02fdf72A7fa',
    43113: '0x3cc02b2e9a0ce37c088edf80a95128edc35555e6',
    420: '0x9EB7E00D452F5ECeD9B81027cdBF75d6b038f952',
    421613: '0x9eb7e00d452f5eced9b81027cdbf75d6b038f952'
}

const lpVault = {
    1: '0xF12704791Aa4035cF8b625Af88F3d980F7065F8b',
    56: '0xF12704791Aa4035cF8b625Af88F3d980F7065F8b',
    66: '0xF12704791Aa4035cF8b625Af88F3d980F7065F8b',
    137: '0xF12704791Aa4035cF8b625Af88F3d980F7065F8b',
    321: '0xF12704791Aa4035cF8b625Af88F3d980F7065F8b',
    43114: '0xF12704791Aa4035cF8b625Af88F3d980F7065F8b',
    10: '0xF12704791Aa4035cF8b625Af88F3d980F7065F8b',
    42161: '0xF12704791Aa4035cF8b625Af88F3d980F7065F8b',
    
    3: '0x0E2B0f3c0dB2C4c1712Fc0850fb91E4eDc384f16',
    97: '0x0E2B0f3c0dB2C4c1712Fc0850fb91E4eDc384f16',
    65: '0x2f95c824e125ff8aae1b7449ed794510fac2093b',
    80001: '0x0E2B0f3c0dB2C4c1712Fc0850fb91E4eDc384f16',
    322: '0x0E2B0f3c0dB2C4c1712Fc0850fb91E4eDc384f16',
    43113: '0xb17cA596e565423671D4D4C065abBd9F0Bd82760',
    420: '0x5480F9D7C068233a3385125AD5dA354F677a7768',
    421613: '0x5480F9D7C068233a3385125AD5dA354F677a7768'

}

export default {
    vestingVault,
    lpVault
}