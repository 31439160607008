import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { isAddress } from '../utils'

import { useActiveWeb3React } from './index'
import { getPairContract, getTokenContract } from '../utils/contractHelpers'

export interface Token {
  readonly tokenAddress: string;
  readonly decimals: number;
  readonly totalSupply: BigNumber;
  readonly symbol: string;
  readonly name: string;
  readonly balance: BigNumber | undefined;
}

// export function useTokenDetails() {
  
//   const [name, setName] = useState('')
//   const [decimals, setDecimals] = useState(18)
//   const [totalSupply, setTotalSupply] = useState<BigNumber>()
//   const [balance, setBalance] = useState<BigNumber>()
//   const [symbol, setSymbol] = useState('')
//   const { library, account, chainId } = useActiveWeb3React()
//   const [isERC20Address, setIsERC20Address] = useState(true)

//   const getTokenName = async (address: string | undefined): Promise<string | undefined> => {
//     const tokenContract = getTokenContract(address, library, account)
//     return await tokenContract?.name()
//   }

//   const getTokenSymbol = async (address: string | undefined): Promise<string | undefined> => {
//     const tokenContract = getTokenContract(address, library, account)
//     return await tokenContract?.symbol()
//   }

//   const fetchTokenDetails = useCallback(async (tokenAddress: string, tokenType: 'token' | 'pair') => {
//     const address = isAddress(tokenAddress)
//     try {
//       if(!address || !account) {
//         console.log("Nullifiy")
//         setName('')
//         setDecimals(0)
//         setSymbol('')
//         return null
//       }
//       const tokenContract = getTokenContract(tokenAddress, library, account)
//       const pairContract = getPairContract(tokenAddress, library, account)
//       if (tokenType === 'token') {
//         console.log("fetching again")
//         const name = await tokenContract?.name()
//         setName(name)
//         const decimals = await tokenContract?.decimals()
//         setDecimals(decimals)
//         const totalSupply = await tokenContract?.totalSupply()
//         setTotalSupply(totalSupply)
//         const balance = await tokenContract?.balanceOf(account)
//         setBalance(balance)
//         const symbol = await tokenContract?.symbol()
//         setSymbol(symbol)
//       } else {
//         const token0Address = await pairContract?.token0()
//         const token1Address = await pairContract?.token1()
//         const token0Name = await getTokenName(token0Address)
//         const token1Name = await getTokenName(token1Address)
//         const token0Symbol = await getTokenSymbol(token0Address)
//         const token1Symbol = await getTokenSymbol(token1Address)
//         setName(token0Name + '/' + token1Name)
//         setSymbol(token0Symbol + '/' + token1Symbol)
//         const decimals = await pairContract?.decimals()
//         setDecimals(decimals)
//         const totalSupply = await pairContract?.totalSupply()
//         setTotalSupply(totalSupply)
//         const balance = await pairContract?.balanceOf(account)
//         setBalance(balance)
//       }
//       setIsERC20Address(true)

//       // if (!name || !decimals || !totalSupply || !symbol) {
//       //   console.log("empty token", name, decimals, totalSupply, symbol ?? 1)
//       //   return null
//       // }
      
//       return {
//         tokenAddress,
//         name,
//         decimals,
//         totalSupply,
//         balance,
//         symbol
//       }
//     } catch (error) {
//       setIsERC20Address(false)
//       console.log(error)
//       return undefined
//     }
//   }, [])

//   return { onFetchToken: fetchTokenDetails }
// }

export function useToken(tokenAddress: string, tokenType: 'token' | 'pair'): Token | null | undefined {
  const address = isAddress(tokenAddress)
  const [name, setName] = useState('')
  const [decimals, setDecimals] = useState(18)
  const [totalSupply, setTotalSupply] = useState<BigNumber>()
  const [balance, setBalance] = useState<BigNumber>()
  const [symbol, setSymbol] = useState('')
  const { library, account, chainId } = useActiveWeb3React()
  const [isERC20Address, setIsERC20Address] = useState(true)

  useEffect(() => {
    
    const getTokenName = async (address: string | undefined): Promise<string | undefined> => {
      const tokenContract = getTokenContract(address, library, account)
      return await tokenContract?.name()
    }
  
    const getTokenSymbol = async (address: string | undefined): Promise<string | undefined> => {
      const tokenContract = getTokenContract(address, library, account)
      return await tokenContract?.symbol()
    }

    const fetchTokenDetails = async () => {
      try {
        if(!address || !account) {
          console.log("Nullifiy")
          setName('')
          setDecimals(0)
          setSymbol('')
          return null
        }
        const tokenContract = getTokenContract(tokenAddress, library, account)
        const pairContract = getPairContract(tokenAddress, library, account)
        if (tokenType === 'token') {
          console.log("fetching again")
          const name = await tokenContract?.name()
          setName(name)
          const decimals = await tokenContract?.decimals()
          setDecimals(decimals)
          const totalSupply = await tokenContract?.totalSupply()
          setTotalSupply(totalSupply)
          const balance = await tokenContract?.balanceOf(account)
          setBalance(balance)
          const symbol = await tokenContract?.symbol()
          setSymbol(symbol)
        } else {
          const token0Address = await pairContract?.token0()
          const token1Address = await pairContract?.token1()
          const token0Name = await getTokenName(token0Address)
          const token1Name = await getTokenName(token1Address)
          const token0Symbol = await getTokenSymbol(token0Address)
          const token1Symbol = await getTokenSymbol(token1Address)
          setName(token0Name + '/' + token1Name)
          setSymbol(token0Symbol + '/' + token1Symbol)
          const decimals = await pairContract?.decimals()
          setDecimals(decimals)
          const totalSupply = await pairContract?.totalSupply()
          setTotalSupply(totalSupply)
          const balance = await pairContract?.balanceOf(account)
          setBalance(balance)
        }
        setIsERC20Address(true)
      } catch (error) {
        setIsERC20Address(false)
        console.log(error)
        return undefined
      }
    }

    if (account) {
      fetchTokenDetails()
    }
  }, [account, address, library, tokenType, chainId, isERC20Address])

  // console.log("token returned", isERC20Address, name, decimals, totalSupply, symbol, balance)
  if (!isERC20Address) {
    console.log("isERC20Address")
    return undefined
  }
  if (!name || !decimals || !totalSupply || !symbol) {
    console.log("empty token")
    return null
  }

  return {
    tokenAddress,
    name,
    decimals,
    totalSupply,
    balance,
    symbol
  }
}