import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectToHome = () => {
    return (
        <>
            <Redirect to="/" />
        </>
    )
}

export default RedirectToHome