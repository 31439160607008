import { baseUrl  } from "./basePath"

export function getList() {
    let url = baseUrl + "vault/list"
    return fetch(url)
        .then(data => data.json())
}

export function getLockDetails(tokenAddress: string) {
    let url = baseUrl + "vault/lockdata"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({ tokenAddress: tokenAddress })
    })
        .then(data => data.json())
}