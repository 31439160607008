import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import RangeSlider from '../../components/RangeSlider';
import { getLockDetails } from '../../services/vault';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import './lock-details-lp-token.scss';
import { useWeb3React } from '@web3-react/core';
import { useWithdrawLock } from '../../hooks/LpVault';
import BigNumber from 'bignumber.js'
import { getExplorerUrl, getExplorerUrlFromName, getEllipsisAddress, isAddress, getExplorerNameFromName, getPurposeName } from '../../utils';
import { toast } from 'react-toastify'

const LpLockDetails = () => {
    const { account } = useWeb3React()
    dayjs.extend(relativeTime);
    const [data, setData] = useState<any>();
    let { tokenAddress } = useParams<{ tokenAddress: string }>();
    const [withdrawRequested, setwithdrawRequested] = useState(false)
    const [withdrawDepositId, setwithdrawDepositId] = useState(0)
    const [withdrawTxhash, setWithdrawTxhash] = useState('');
    const { onWithdrawLock } = useWithdrawLock()

    const handleWithdrawLock = useCallback(async (depositId) => {
        try {
            if (withdrawRequested) return
            setwithdrawRequested(true)
            let tx = await onWithdrawLock(
                new BigNumber(depositId).toString()
            )
            setwithdrawRequested(false)
            if (tx) {
                setwithdrawDepositId(depositId)
                setWithdrawTxhash(tx.hash)
            }
        } catch (e) {
            console.error(e)
        }
    }, [onWithdrawLock])

    useEffect(() => {
        const address = isAddress(tokenAddress)
        if (!address) {
            toast.error("Invalid token address")
            return
        }
        getLockDetails(address).then((res) => {
            console.log(res)
            if (res.status === "success") {
                let tokenLock = res.data[0]
                if (!tokenLock) {
                    toast.warning("No lock found! Please hit reload in sometime, if you recently submitted Lock Request")
                    return
                }
                tokenLock.tokenLocked = 0
                tokenLock.chain = ""
                tokenLock.nextReleaseTime = 100000000000000
                tokenLock.lockitems.forEach((lock: any) => {
                    if(!lock) return 
                    tokenLock.tokenLocked += lock.amount
                    tokenLock.chain = lock.chain
                    if (tokenLock.nextReleaseTime > lock.unlockTimestamp && lock.isWithdrawn === false && (lock.unlockTimestamp > Date.now() / 1000)) {
                        tokenLock.nextReleaseTime = lock.unlockTimestamp
                    }
                })
                tokenLock.totalValueLocked = '$' + (tokenLock.tokenLocked * 0)
                setData(tokenLock)
            }
        })

    }, [])

    const ownerLoggedIn = function (withdrawalAddress: string) {
        if (!account || !withdrawalAddress) return false
        console.log(account, withdrawalAddress)
        if (account.toLocaleLowerCase() === withdrawalAddress.toLocaleLowerCase()) return true
        else return false
    }

    return (
        <>
            <Container fluid className="lp-vault-explorer-container brand-bgcolor-shadow-black pl-0 pr-0 pb-5">
                <PageHeader title='Vault Explorer' />
                <div className="vault-content brand-color-white">
                    <div className="project-details-container d-flex flex-lg-row mb-4">
                        <div className="project-details col">
                            <div className="d-flex align-items-center">
                                {(data?.lockitems[0].chain === "ETH Testnet" || data?.lockitems[0].chain === "ETH") && <img src="../images/eth_logo_round.png" className="mr-4 project-icon" />}
                                {(data?.lockitems[0].chain === "BSC Testnet" || data?.lockitems[0].chain === "BSC") && <img src="../images/bsc-logo.png" className="mr-4 project-icon" />}
                                {(data?.lockitems[0].chain === "Polygon Testnet" || data?.lockitems[0].chain === "Polygon" || data?.lockitems[0].chain === "MATIC Testnet") && <img src="../images/polygon-matic-logo.png" className="mr-4 project-icon" />}
                                {(data?.lockitems[0].chain === "KCC Testnet" || data?.lockitems[0].chain === "KCC") && <img src="../images/kcc-logo.svg" className="mr-4 project-icon" />}
                                {(data?.lockitems[0].chain === "Avalanche Mainnet C-Chain" || data?.lockitems[0].chain === "Avalanche FUJI C-Chain") && <img src="../images/avax-logo.png" className="mr-4 project-icon" />}
                                {(data?.lockitems[0].chain === "OEC") && <img src="../images/oec-logo.png" className="mr-4 project-icon" />}
                                {(data?.lockitems[0].chain === "Optimism") && <img src="../images/optimism-logo.svg" className="mr-4 project-icon" />}
                                {(data?.lockitems[0].chain === "Arbitrum") && <img src="../images/arbitrum-logo.png" className="mr-4 project-icon" />}
                                <div>
                                    <h2 className="font-bold mb-0">{data?.name}</h2>
                                    <h6 className='brand-color mb-0'>{data?.lockitems[0] && data?.lockitems[0].chain}</h6>
                                    <p className="text-break">{data?.address}</p>
                                </div>
                            </div>

                        </div>
                        <div className="lock-overview col">
                            <div className="progress-bar">
                                <RangeSlider
                                    className="range-slider"
                                    custom={true}
                                    value={(data?.tokenLocked / data?.totalSupply) * 100}
                                    type='range'
                                    readOnly={true}
                                />
                            </div>
                            <div className="details-view ">
                                <div className="detail-item d-flex justify-content-between">
                                    <p className="mb-0">{data?.symbol} Locked</p>
                                    <p className="mb-0">{data?.tokenLocked} {data?.symbol}</p>
                                </div>
                                <div className="detail-item d-flex justify-content-between">
                                    <p className="mb-0">Total Supply</p>
                                    <p className="mb-0">{data?.totalSupply}</p>
                                </div>
                                <div className="detail-item d-flex justify-content-between">
                                    <p className="mb-0">Total Value Locked</p>
                                    <p className="mb-0">{data?.totalValueLocked}</p>
                                </div>
                                <div className="detail-item d-flex justify-content-between">
                                    <p className="mb-0">Next Release</p>
                                    <p className="mb-0">{data?.nextReleaseTime === 100000000000000 ? "Released" : dayjs(dayjs.unix(data?.nextReleaseTime).format("YYYY-MM-DD HH:mm:ss")).fromNow(true).toUpperCase()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lock-list-container">
                        <div className="list-title">
                            <h5 className="mb-0">{data?.symbol} Unlock Schedule</h5>
                        </div>
                        {data?.lockitems.map((obj: any, index: any) => (
                            <div className="list-item" key={`item-${index}`}>
                                <Row className="align-items-center">
                                    {/* <p className="badge badge-success badge-rotate">Locked</p> */}
                                    <Col lg={4} xs={8} className="details-section">
                                        <p className="font-bold mb-1">Locked {data?.symbol}: <span className="brand-color">{Math.floor(obj.amount * 10000) / 10000}</span></p>
                                        <p className="font-bold mb-1">Purpose: <span className="brand-color">{obj.purpose === 99 ? 'Liquidity Lock' : getPurposeName(obj.purpose)}</span></p>
                                        <div className="d-flex align-items-center">
                                            <img src="../images/lock-btn-icon.svg" width="12" />
                                            <p className="mb-0 ml-2">{new Date(obj.lockTimestamp * 1000).toLocaleDateString()}</p>
                                            <img className="ml-4" src="../images/vault-detail-unlock.svg" width="12" />
                                            <p className="mb-0 ml-2">{new Date(obj.unlockTimestamp * 1000).toLocaleDateString()}</p>
                                        </div>
                                    </Col>
                                    <Col lg={2} xs={4} className="floting-status text-center p-0 m-auto">
                                        {obj.unlockTimestamp > (Date.now() / 1000) && (
                                            <div className="status-card py-1 d-flex align-items-center">
                                                <div className="img-contaniner">
                                                    <img className="img" src="../images/lock-details-lock.svg" />
                                                </div>
                                                <div className="text-container">
                                                    <h6 className="mb-0 font-bold">LOCKED</h6>
                                                </div>
                                            </div>
                                        )}
                                        {obj.isWithdrawn === true && (
                                            <div className="status-card py-1 d-flex align-items-center">
                                                <div className="img-contaniner">
                                                    <img className="img" src="../images/lock-details-withdrawn.svg" />
                                                </div>
                                                <div className="text-container">
                                                    <h6 className="mb-0 font-bold">WITHDRAWN</h6>
                                                </div>
                                            </div>
                                            // <p className="badge badge-dark">WITHDRAWN</p>
                                        )}
                                        {obj.isWithdrawn === false && obj.unlockTimestamp < (Date.now() / 1000) && ownerLoggedIn(obj.withdrawal) && (
                                            <button className="status-card py-1 d-flex align-items-center" onClick={() => {
                                                handleWithdrawLock(obj.depositId)
                                            }}>
                                                <div className="img-contaniner">
                                                    <img className="img" src="../images/lock-details-withdrawn.svg" />
                                                </div>
                                                <div className="text-container">
                                                    <h6 className="mb-0 font-bold">CLAIM</h6>
                                                </div>

                                            </button>
                                        )}
                                        {withdrawDepositId === obj.depositId && withdrawTxhash !== '' && (
                                            <a className="withdraw-tx-link" href={getExplorerUrlFromName(obj.chain) + 'tx/' + withdrawTxhash} target="_blank"> {"View on " + getExplorerNameFromName(obj.chain)} </a>
                                        )}
                                        {obj.isWithdrawn === false && obj.unlockTimestamp < (Date.now() / 1000) && !ownerLoggedIn(obj.withdrawal) && (
                                            <div className="status-card py-1 d-flex align-items-center">
                                                <div className="img-contaniner">
                                                    <img className="img" src="../images/lock-details-unlock.svg" />
                                                </div>
                                                <div className="text-container">
                                                    <h6 className="mb-0 font-bold">UNLOCKED</h6>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col lg={5} sm={12} className="progress-section">
                                        <p className="mb-0">Unlock Countdown:
                                        {obj.unlockTimestamp > (Date.now() / 1000) && (<span className="h6">{dayjs(dayjs.unix(obj.unlockTimestamp).format("YYYY-MM-DD HH:mm:ss")).toNow(true).toUpperCase()}</span>)}
                                            {obj.unlockTimestamp < (Date.now() / 1000) && (<span className="h6"> 0 SEC</span>)}
                                        </p>
                                        <div className="progress-bar">
                                            <RangeSlider
                                                className="range-slider"
                                                custom={true}
                                                value={100 - (obj.unlockTimestamp - (Date.now() / 1000)) / (obj.unlockTimestamp - obj.lockTimestamp) * 100}
                                                type='range'
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="text-right withdrawal-address">
                                            <a
                                                href={getExplorerUrlFromName(obj.chain) + 'address/' + obj.withdrawal}
                                                target="_blank">
                                                {/* const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null; */}
                                                {getEllipsisAddress(obj.withdrawal)}
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ))}

                    </div>
                </div>

            </Container>
        </>
    )
}

export default LpLockDetails