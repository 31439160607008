import BigNumber from 'bignumber.js'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 20,
  ROUNDING_MODE: BigNumber.ROUND_DOWN
})

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = (new BigNumber(balance.toString()).dividedBy(new BigNumber(10).pow(decimals)))
  return displayBalance.decimalPlaces(decimals).toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const displayBalance = (new BigNumber(balance.toString()).dividedBy(new BigNumber(10).pow(decimals)))
  return displayBalance.toString()
}

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number.toLocaleString(undefined, options)
}
