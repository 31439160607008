import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import './home.scss';

import BrandImg from './images/brand-img.svg';

import ImgMediaYahoo from './images/media-yahoo.png';
import ImgMediaHackernoon from './images/media-hackernoon.png';
import ImgMediaTechbullion from './images/media-techbullion.png';
import ImgMediaMarket from './images/media-market-watch.svg';
import ImgMediaDJ from './images/media-digital-journal.png';

import ImgProductCenterLogo from './images/center-logo.png'
import ImgProductRaiseFund from './images/product-raise-funds.png';
import ImgProductStaking from './images/product-staking.png';
import ImgProductFarm from './images/product-farm.png';
import ImgProductLockLp from './images/product-lock-lp.png';
import ImgProductVesting from './images/product-vesting.png';
import ImgProductMint from './images/product-mint.png';
import ImgProductRaiseFundLeft from './images/product-raise-left.png';
import ImgProductMintRight from './images/product-mint-right.png';

import ImgTokenomics from './images/tokenomics.png';

import Border from './images/border.png';
import ImgUtilityBuyBack from './images/utility-buy-back-burn.png';
import ImgUtilityCommunity from './images/utility-community-voting.png';
import ImgUtilityMining from './images/utility-mining-rewards.png';
import ImgUtilityPlatform from './images/utility-platform-fees.png';
import ImgUtilitySale from './images/utility-sale-participation.png';
import ImgUtilityStaking from './images/utility-staking-incentives.png';

import ImgAdvisorMudit from './images/advisor-mudit-marda.jpg';
import ImgAdvisorKha from './images/advisor-kha-nguyen.jpg';
import ImgAdvisorThierry from './images/advisor-thierry-rosete.png';
// import ImgAdvisorFlorian from './images/advisor-florian-hermet.jpg';
import ImgAdvisorMarco from './images/advisor-marco.jpg';

import ImgBackerSVC from './images/backer-skyvision-capital.png';
import ImgBackerDuckDao from './images/backer-duckdao.png';
import ImgBackerBlackDragon from './images/backer-blackdragon.png';
import ImgBackerDCI from './images/backer-dci.png';
import ImgBackerNodeseeds from './images/backer-nodeseeds.png';
import ImgBackerHalving from './images/backer-halvings-capital.png';
import ImgBackerNewField from './images/backer-newfieldfund.png';
import ImgBackerJ8 from './images/backer-j8ventures.png';
import ImgBackerFerra from './images/backer-fera-strategies.png';
import ImgBackerAsync from './images/backer-async-capital.png';
import ImgBackerTravelerCapital from './images/backer-traveler-capital.png';
import ImgBackerConnectico from './images/backer-connectico-capita.png';
import ImgBackerRedhat from './images/backer-redhat-capital.png';
import ImgBackerMazee from './images/backer-mazee.svg';
import ImgBackerRegain from './images/backer-regain.png';
import ImgBackerDreamboat from './images/backer-dreamboat.png';
import ImgBackerCspdao from './images/backer-cspdao.svg';
import ImgBackerBBS from './images/backer-bbs.png';
import ImgBackerBCA from './images/backer-bca.png';
import ImgBackerInfinity from './images/backer-infinity.png';
import ImgBackerGirnas from './images/backer-girnas.svg';
import ImgBackerAtomHeart from './images/backer-atom-heart.svg';
import ImgBackerKenzo from './images/backer-kenzo.png';
import ImgBackerMH from './images/backer-mh.png';
import ImgBackerR8 from './images/backer-r8-capital.png';
import ImgBackerAtesis from './images/backer-atesis.png';
import ImgBackerExcalibur from './images/backer-excalibur.png';
import ImgBackerGrizzly from './images/backer-grizzly.png';
import ImgBackerGSV from './images/backer-gsv.png';
import ImgBackerIRV from './images/backer-irv.svg';

import ImgPartnerAck from './images/partner-acknoledger.svg';
import ImgPartnerForward from './images/partner-forward.svg';
import ImgPartnerPolkaex from './images/partner-polkaex.svg';
import ImgPartnerRocketx from './images/partner-rocketx.svg';
import ImgPartnerStrip from './images/partner-strip.svg';
import ImgPartnerMaticLaunch from './images/partner-maticlaunch.png';

import ImgChainPolygon from './images/polygon-logo.svg';
import ImgChainKCC from './images/kcc-logo.svg';
import ImgChainAvax from './images/avalanche_horizontal.png';
import ImgChainOex from './images/oex_oec_logo.svg';

import ImgHeroAuditedByQuill from './images/audited-by-quillhash-white.png';
import ImgHeroAuditedByCertik from './images/audited-by-certik.svg';
import ImgHeroAuditedByImmune from './images/audited-by-immunebytes.png';
// import PitchDeck from './docs/pitchdeck.pdf';

import LogoCMC from "./images/logo_cmc.png";
import LogoDexTools from "./images/logo_dextools.png";
import LogoGecko from "./images/logo_coingecko.png";


const Home = () => {
    return (
        <>
            <Container fluid className="home-page brand-bgcolor-shadow-black pl-0 pr-0">
                <div className='title-area-bgcolor brand-bg-img content-padding content-hero-padding section-margin-hero '>
                    <div className='brand-content d-flex align-items-center'>
                        <Row className="">
                            <Col className="brand-title" lg={6} sm={6}>
                                <h1 className="brand-color mb-1 display-3 font-bold">
                                    Blockius
                                </h1>
                                <h3 className="brand-color-white mb-0 font-bold sub-title">
                                    A Multichain DeFi Gateway For The
                                </h3>
                                <h3 className="brand-color mb-2 font-bold sub-title">
                                    Web 3.0 Ecosystem!
                                </h3>
                                <p className="brand-color-grey mb-3">
                                    The Blockius is a suite of applications that acts as a gateway to the Web 3.0 ecosystem. It predominantly solves the issue of investor confidence in DeFi by providing  various dApps with compatibility of multiple blockchains.
                                </p>
                                <Row className='mb-4'>
                                    <Col>
                                        <a target="_blank" href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xd5e950837Ad48D08baD2f87bFcF8eD7167bB44BC" className='mr-1'><button className="btn btn-brand mr-3 font-bold">BUY $BLOS </button></a>
                                        <a target="_blank" href="https://coinmarketcap.com/currencies/blockpad/" className='mr-3'><img src={LogoCMC} width={31} /></a>
                                        <a target="_blank" href="https://www.coingecko.com/en/coins/blockius" className='mr-3'><img src={LogoGecko} width={31} /></a>
                                        <a target="_blank" href="https://www.dextools.io/app/bsc/pair-explorer/0x590acb1c067db3782021bf36edfa8845d5db132e" className='mr-2'><img src={LogoDexTools} width={28} /></a>
                                        {/* <a target="_blank" href="https://blockius.s3.amazonaws.com/pitchdeck.pdf"><button className="btn btn btn-brand-border mr-3"> Pitch Deck </button></a> */}
                                        {/* <a href={require('./docs/pitchdeck.pdf').default}><button className="btn btn-brand mr-3"> Pitch Deck </button></a> */}
                                        {/* <a href="https://theblockpad.docsend.com/view/56qxv5s2s7hzj87i"><button className="btn btn-brand-border"> One Pager </button></a> */}
                                    </Col>
                                </Row>
                                <div className='audited-by-container'>
                                    {/* <Col xs={3} className='text-right'> */}
                                    <h6 className='mb-0 mr-2 audit-by-title'>Audited By : </h6>
                                    {/* </Col>
                                    <Col> */}
                                    <div>
                                        <a target="_blank" className="audit-logo" href="https://www.certik.com/projects/blockius"><img className='img-fluid mr-2 certik-audit-logo' src={ImgHeroAuditedByCertik} width={120} /></a>
                                        <a target="_blank" className="audit-logo" href="https://github.com/Quillhash/Audit_Reports/blob/master/BPADVault%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf"><img className='img-fluid ml-2 mr-2' src={ImgHeroAuditedByQuill} width={140} /></a>
                                        <a target="_blank" className="audit-logo" href="https://github.com/ImmuneBytes/Smart-Contract-Audit-Reports/blob/main/BlockPad/BlockPad%20-%20Final%20Audit%20Report%20.pdf"><img className='img-fluid ml-2 immune-logo' src={ImgHeroAuditedByImmune} width={140} /></a>
                                    </div>
                                    {/* </Col> */}
                                </div>
                                {/* <img src={ImgHeroAuditedByQuill} width={150}/> */}
                                {/* <Row>
                                <Col className="mt-3">
                                    <a href="https://theblockpad.typeform.com/to/wb3DqaTm" target="_blank" className="public-sale-link">Fill Private Sale Interest Form &#62;&#62;</a>
                                </Col>
                            </Row> */}
                            </Col>
                            <Col className="brand-img-conatiner" lg={6} sm={6}>
                                <img alt="brand rocket" className="img-fluid brand-img" src={BrandImg} />
                            </Col>
                        </Row>

                    </div>

                </div>
                <div className="background-wrapper">
                    <div className='media-section section-margin'>
                        {/* <div className="section-header">
                            <h6 className="section-sub-title mb-0">BLOCKPAD FAMILY<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Backers & Partners<span className="brand-color">.</span></h1>
                        </div> */}
                        <div className="content-padding d-flex align-items-center media-section-flex">
                            <div className="title-container d-flex align-items-center">
                                <h5 className="media-title mb-0 text-nowrap">IN THE MEDIA</h5>
                            </div>
                            <Row className="text-center align-items-center justify-content-around">
                                <Col className="media-logo py-2 px-4" lg={2} xs={6}>
                                    <img className="img-fluid mobile-small" alt="yahoo finance" src={ImgMediaYahoo} width="110" />
                                </Col>
                                <Col className="media-logo py-2 px-4" lg={2} xs={6}>
                                    <img className="img-fluid " alt="hackernoon" src={ImgMediaHackernoon} width="300" />
                                </Col>

                                <Col className="media-logo py-2 px-4" lg={2} xs={6}>
                                    <img className="img-fluid mobile-small" alt="techbullion" src={ImgMediaTechbullion} width="170" />
                                </Col>
                                <Col className="media-logo py-2 px-4" lg={2} xs={6}>
                                    <img className="img-fluid " alt="market watch" src={ImgMediaDJ} width="400" />
                                </Col>

                                <Col className="media-logo py-2 px-4" lg={2} xs={6}>
                                    <img className="img-fluid " alt="market watch" src={ImgMediaMarket} width="200" />
                                </Col>
                                {/* <Col className="media-logo py-2 px-4" lg={2} xs={6}>
                                    <img className="img-fluid " alt="market watch" src={ImgMediaDJ} width="200" />
                                </Col> */}
                            </Row>
                        </div>
                    </div>
                    <div className='product-section section-margin '>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Our Products<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Suite of Applications<span className="brand-color">.</span></h1>
                        </div>
                        <Row className="content-padding">
                            <Col lg={12}>
                                
                                <div className="product-card product-card-reverse-mobile text-right">
                                <div className="lp-live-parent-container d-flex align-items-center">
                                    <div className="lp-live-container d-flex align-items-center yeild-fix">
                                        <Spinner className="live-loader" animation="grow" />
                                        <p className="lp-live mb-0">LIVE</p>
                                    </div>
                                </div>
                                    <img alt="Manage Investor Token Distribution - It allows teams to handle private investor token distribution utilizing a dedicated claim dashboard"
                                        className="img-fluid" src={ImgProductMintRight} />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="lp-live-parent-container d-flex align-items-center center-fix product-card-reverse">
                                    <div className="lp-live-container d-flex align-items-center yeild-fix">
                                        <Spinner className="live-loader" animation="grow" />
                                        <p className="lp-live mb-0">LIVE</p>
                                    </div>
                                </div>
                                <div className="product-card-reverse text-center">
                                    <img alt="Manage Investor Token Distribution - It allows teams to handle private investor token distribution utilizing a dedicated claim dashboard"
                                        className="img-fluid" src={ImgProductMint} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="vesting-live-container d-flex align-items-center">
                                    <Spinner className="live-loader" animation="grow" />
                                    <p className="vesting-live mb-0">LIVE</p>
                                </div>
                                <div className="product-card product-card-first text-left">
                                    <img alt="Create Customizable Vesting - It allows you to create a highly customizable vesting schedule for your tokens and exhibit trust among community."
                                        className="img-fluid" src={ImgProductVesting} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="lp-live-parent-container d-flex align-items-center">
                                    <div className="lp-live-container d-flex align-items-center">
                                        <Spinner className="live-loader" animation="grow" />
                                        <p className="lp-live mb-0">LIVE</p>
                                    </div>
                                </div>
                                <div className="product-card product-card-first text-right">
                                    <img alt="Lock LP-Generated Tokens - It allows LP providers to lock generated LP tokens for a certain period of time and build trustful environment."
                                        className="img-fluid" src={ImgProductLockLp} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="vesting-live-container d-flex align-items-center staking-fix">
                                    <Spinner className="live-loader" animation="grow" />
                                    <p className="vesting-live mb-0">LIVE</p>
                                </div>
                                <div className="product-card product-card-second text-left">
                                    <img alt="Enjoy Yields on Staking - It allows teams to create yield omitting staking pools while individuals to stake tokens and reward passive yields."
                                        className="img-fluid" src={ImgProductStaking} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="lp-live-parent-container d-flex align-items-center">
                                    <div className="lp-live-container d-flex align-items-center yeild-fix">
                                        <Spinner className="live-loader" animation="grow" />
                                        <p className="lp-live mb-0">LIVE</p>
                                    </div>
                                </div>
                                <div className="product-card product-card-second text-right">
                                    <img alt="Farm Yields on LP Tokens - It allows teams to create liquidity mining farms while liquidity providers to farm respective pool tokens."
                                        className="img-fluid" src={ImgProductFarm} />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="product-card-reverse text-center">
                                    <img alt="Raise fund Launchpad - It allows you to raise funds for your ingenious ideas, letting community to invest in early-stage projects."
                                        className="img-fluid" src={ImgProductRaiseFund} />
                                    <div className="lp-live-parent-container d-flex align-items-center center-fix">
                                        <div className="lp-live-container d-flex align-items-center yeild-fix">
                                            <Spinner className="live-loader" animation="grow" />
                                            <p className="lp-live mb-0">LIVE</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="product-card product-card-reverse-mobile text-left">
                                    <div className="lp-live-container d-flex align-items-center fund-raise-live-fix">
                                        <Spinner className="live-loader" animation="grow" />
                                        <p className="lp-live mb-0">LIVE</p>
                                    </div>
                                    <img alt="Raise fund Launchpad - It allows you to raise funds for your ingenious ideas, letting community to invest in early-stage projects."
                                        className="img-fluid" src={ImgProductRaiseFundLeft} />
                                </div>
                            </Col>
                        </Row>
                        <div id="outerContainer">
                            <div id="container">
                                <div className="item">
                                    <img alt="BLOS Logo" className='img-product-center-logo' src={ImgProductCenterLogo} width={120} />
                                </div>
                                <div className="circle" style={{ animationDelay: "0s" }}></div>
                                <div className="circle" style={{ animationDelay: "1s" }}></div>
                                <div className="circle" style={{ animationDelay: "2s" }}></div>
                                <div className="circle" style={{ animationDelay: "3s" }}></div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='token-summary-section section-margin '>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Token Summary<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-right-grill text-center pt-5 font-bold">Token Summary<span className="brand-color">.</span></h1>
                        </div>
                        <Row className="mb-5 content-padding d-flex align-items-center ">
                            <Col className="token-summary-img-container" lg={6}>
                                <img alt="Token summary chart" className="img-fluid token-summary-img" src={ImgTokenomics} />
                            </Col>
                            <Col lg={6}>
                                <h5 className="brand-color-white text-center">Token Metrics</h5>
                                <table className="token-summary-table" cellSpacing="10px">
                                    <tbody>
                                        <tr>
                                            <td className="title font-bold">Token Ticker</td>
                                            <td className="value">BLOS</td>
                                        </tr>
                                        <tr>
                                            <td className="title font-bold">Token Type</td>
                                            <td className="value">BEP-20</td>
                                        </tr>
                                        <tr>
                                            <td className="title font-bold">Token Address</td>
                                            <td className="value">TBA</td>
                                        </tr>
                                        <tr>
                                            <td className="title font-bold">Total Supply</td>
                                            <td className="value">10,000,000</td>
                                        </tr>
                                        <tr>
                                            <td className="title font-bold">Token for Sale</td>
                                            <td className="value">2,650,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div> */}
                    <div className='token-utility-section section-margin section-margin-4rem'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">TOKEN UTILITY<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Token Utility<span className="brand-color">.</span></h1>
                        </div>
                        <Row className="content-padding">
                            <Col lg={4}>
                                <div className="feature-card text-center">
                                    <img alt="Sale Participation" className="img-fluid" src={ImgUtilitySale} />
                                    <div className="border-container">
                                        <img className="img-fluid border-img" src={Border} alt='sandlock' />
                                    </div>
                                    <div className="feature-card-body">
                                        <h5 className="brand-color-white font-bold">Sale Participation</h5>
                                        <p className="brand-color-grey">
                                            Enables BLOS stakers to purchase tokens offered by various multichain presales and public sales on launchpad.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="feature-card text-center">
                                    <img alt="Community Voting" className="img-fluid" src={ImgUtilityCommunity} />
                                    <div className="border-container">
                                        <img className="img-fluid border-img" src={Border} alt='sandlock' />
                                    </div>
                                    <div className="feature-card-body">
                                        <h5 className="brand-color-white font-bold">Community Voting</h5>
                                        <p className="brand-color-grey">
                                            From platform decisions to approve projects on launchpad, required governance will be powered by BLOS tokens.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="feature-card text-center">
                                    <img alt="Staking Incentives" className="img-fluid" src={ImgUtilityStaking} />
                                    <div className="border-container">
                                        <img className="img-fluid border-img" src={Border} alt='sandlock' />
                                    </div>
                                    <div className="feature-card-body">
                                        <h5 className="brand-color-white font-bold">Staking Incentives</h5>
                                        <p className="brand-color-grey">
                                            Active stakers will automatically be eligible to receive BLOS rewards post successful governance and IDO events.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="feature-card text-center">
                                    <img alt="Platform Fees" className="img-fluid" src={ImgUtilityPlatform} />
                                    <div className="border-container">
                                        <img className="img-fluid border-img" src={Border} alt='sandlock' />
                                    </div>
                                    <div className="feature-card-body">
                                        <h5 className="brand-color-white font-bold">Platform Fees</h5>
                                        <p className="brand-color-grey">
                                            Offering affordable suite of DeFi applications for teams and communities with a fee paid in BLOS tokens.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="feature-card text-center">
                                    <img alt="Mining Rewards" className="img-fluid" src={ImgUtilityMining} />
                                    <div className="border-container">
                                        <img className="img-fluid border-img" src={Border} alt='sandlock' />
                                    </div>
                                    <div className="feature-card-body">
                                        <h5 className="brand-color-white font-bold">Mining Rewards</h5>
                                        <p className="brand-color-grey">
                                            BLOS as mining rewards to DEX liquidity providers for maintaining liquidity and contributing to a healthy volume.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="feature-card text-center">
                                    <img alt="Buy Back & Burn" className="img-fluid" src={ImgUtilityBuyBack} />
                                    <div className="border-container">
                                        <img className="img-fluid border-img" src={Border} alt='sandlock' />
                                    </div>
                                    <div className="feature-card-body">
                                        <h5 className="brand-color-white font-bold">Buy Back & Burn</h5>
                                        <p className="brand-color-grey">
                                            Certain percentage of fees collected via offered products will be utilized to buy back and burn BLOS tokens periodically.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* <div className='token-utility-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Token Utility<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-right-grill text-center pt-5 font-bold">Token Utility<span className="brand-color">.</span></h1>
                        </div>
                        <Row className="content-padding">
                            <Col lg={4}>
                                <div className="utility-card text-center">
                                    <div className="utility-card-body">
                                        <img alt="Guaranteed Allocation" className="img-fluid" src="images/utility-guaranteed-allocation.svg" />
                                        <h5 className="brand-color-white font-bold">Guaranteed Allocation</h5>
                                        <p className="brand-color-grey">
                                            Based on number of tokens you hold; you will be allocated pool weightage
                                            accordingly allowing you to have a large allocation in quality projects
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="utility-card text-center">
                                    <div className="utility-card-body">
                                        <img alt="Community Voting" className="img-fluid" src="images/utility-community-voting.svg" />
                                        <h5 className="brand-color-white font-bold">Community Voting</h5>
                                        <p className="brand-color-grey">
                                            BPAD token holders with will become eligible to vote in the upcoming
                                            IDOs to choose only high-quality projects making launchpad stronger
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="utility-card text-center">
                                    <div className="utility-card-body">
                                        <img alt="Staking Benefit" className="img-fluid" src="images/utility-staking-benefit.svg" />
                                        <h5 className="brand-color-white font-bold">Staking Benefit</h5>
                                        <p className="brand-color-grey">
                                            For active participation in governance events and token sales,
                                            BPAD stakers will become eligible for our calculated reward organized monthly
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div> */}

                    <div className='advisors-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Advisors<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-right-grill text-center pt-5 font-bold">Advisors<span className="brand-color">.</span></h1>
                        </div>
                        <Row className="content-padding">
                            <Col lg={3} xs={6}>
                                <div className="advisor-card text-center">
                                    <img alt="Advisor - Mudit Marda" className="img-fluid" src={ImgAdvisorMudit} />
                                    <div className="advisor-card-body">
                                        <h5 className="brand-color-white font-bold mb-2">Mudit Marda</h5>
                                        <p className="brand-color-grey advisor-description mb-1">
                                            CTO of Drife
                                        </p>
                                        <p className="brand-color advisor-description advisor-description-position">
                                            Technical Advisor
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} xs={6}>
                                <div className="advisor-card text-center">
                                    <img alt="Advisor - Kha Nguyen" className="img-fluid" src={ImgAdvisorKha} />
                                    <div className="advisor-card-body">
                                        <h5 className="brand-color-white font-bold mb-2">Kha Nguyen</h5>
                                        <p className="brand-color-grey advisor-description mb-1">
                                            Consultant at Tomochain
                                        </p>
                                        <p className="brand-color advisor-description advisor-description-position">
                                            Strategic Advisor
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} xs={6}>
                                <div className="advisor-card text-center">
                                    <img alt="Advisor - Thierry Rosete" className="img-fluid" src={ImgAdvisorThierry} />
                                    <div className="advisor-card-body">
                                        <h5 className="brand-color-white font-bold mb-2">Thierry Rosete</h5>
                                        <p className="brand-color-grey advisor-description mb-1">
                                            Core Member of DCI
                                        </p>
                                        <p className="brand-color advisor-description advisor-description-position">
                                            Marketing Advisor
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} xs={6}>
                                <div className="advisor-card text-center">
                                    <img alt="Advisor - Marco Calicchia" className="img-fluid" src={ImgAdvisorMarco} />
                                    <div className="advisor-card-body">
                                        <h5 className="brand-color-white font-bold mb-2">Marco Calicchia</h5>
                                        <p className="brand-color-grey advisor-description mb-1">
                                            Ex-BD at Certik
                                        </p>
                                        <p className="brand-color advisor-description advisor-description-position">
                                            Ecosystem Advisor
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='backers-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Investors<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Backers<span className="brand-color">.</span></h1>
                        </div>
                        <Row className="content-padding text-center align-items-center">
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="sky vision capital" src={ImgBackerSVC} width="120" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid" alt="duck dao" src={ImgBackerDuckDao} width="170" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="Mazee venture" src={ImgBackerMazee} width="120" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid " alt="Nodeseeds" src={ImgBackerNodeseeds} width="200" />
                            </Col>


                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-left-small" alt="Redhat" src={ImgBackerRedhat} width="60" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid " alt="NewField Fund" src={ImgBackerNewField} width="220" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="Regain venture" src={ImgBackerRegain} width="125" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid " alt="Black Dragon" src={ImgBackerBlackDragon} width="190" />
                            </Col>


                            <Col className="backer-logo py-2" lg={{ span: 3 }} xs={6}>
                                <img className="img-fluid mobile-small" alt="Infinity capital" src={ImgBackerInfinity} width="90" />
                            </Col>
                            <Col className="backer-logo py-2" lg={{ span: 3 }} xs={6}>
                                <img className="img-fluid " alt="Dreamboat" src={ImgBackerDreamboat} width="170" />
                            </Col>
                            <Col className="backer-logo py-2" lg={{ span: 3 }} xs={6}>
                                <img className="img-fluid mobile-small" alt="BBS Finance" src={ImgBackerBBS} width="160" />
                            </Col>
                            <Col className="backer-logo py-2" lg={{ span: 3 }} xs={6}>
                                <img className="img-fluid" alt="Async capital" src={ImgBackerAsync} width="170" />
                            </Col>



                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="BCA Investment" src={ImgBackerBCA} width="120" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid " alt="Cspdao" src={ImgBackerCspdao} width="130" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="J8 ventures" src={ImgBackerJ8} width="100" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="DCI" src={ImgBackerDCI} width="100" />
                            </Col>


                            <Col className="backer-logo py-2" lg={{ span: 3 }} xs={6}>
                                <img className="img-fluid mobile-left-small" alt="Traveler capital" src={ImgBackerTravelerCapital} width="70" />
                            </Col>
                            <Col className="backer-logo py-2" lg={{ span: 3 }} xs={6}>
                                <img className="img-fluid " alt="Atom heart capital" src={ImgBackerAtomHeart} width="130" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-left-small" alt="Halvings capital" src={ImgBackerHalving} width="75" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid " alt="Connect ICO" src={ImgBackerConnectico} width="180" />
                            </Col>

                            <Col className="backer-logo py-2" lg={{ span: 3, offset: 0 }} xs={6}>
                                <img className="img-fluid " alt="Girnas capital" src={ImgBackerGirnas} width="140" />
                            </Col>
                            <Col className=" py-2" lg={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid " alt="Kenzo venture" src={ImgBackerKenzo} width="130" />
                            </Col>
                            <Col className=" py-2" lg={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid mobile-left-small" alt="MH venture" src={ImgBackerMH} width="70" />
                            </Col>
                            <Col className=" py-2" lg={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid mobile-left-small" alt="ferra strateges" src={ImgBackerFerra} width="65" />
                            </Col>

                            <Col className=" py-2" lg={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid mobile-left-small" alt="excalibur capital strateges" src={ImgBackerExcalibur} width="65" />
                            </Col>
                            <Col className=" py-1" lg={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid mobile-left-small" alt="ido research venture" src={ImgBackerIRV} width="170" />
                            </Col>
                            <Col className=" py-2" lg={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid mobile-left-small" alt="R8 capital" src={ImgBackerR8} width="90" />
                            </Col>
                            <Col className=" py-2" lg={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid mobile-left-small" alt="atesis" src={ImgBackerAtesis} width="55" />
                            </Col>


                            <Col className=" py-2" lg={{ span: 3, offset: 3 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid mobile-small" alt="Grizzly capital" src={ImgBackerGrizzly} width="170" />
                            </Col>
                            <Col className=" py-1" lg={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
                                <img className="img-fluid mobile-left-small" alt="Golden shovel" src={ImgBackerGSV} width="80" />
                            </Col>

                        </Row>
                    </div>
                    <div className='backers-section section-margin'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Ecosystem<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-left-grill text-center pt-5 font-bold">Partners<span className="brand-color">.</span></h1>
                        </div>
                        <Row className="content-padding text-center align-items-center">
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid" alt="Forward Protocol" src={ImgPartnerForward} width="160" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="Strip" src={ImgPartnerStrip} width="125" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid " alt="Polkaex" src={ImgPartnerPolkaex} width="130" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="Acknoledger" src={ImgPartnerAck} width="135" />
                            </Col>

                            <Col className="backer-logo py-2" lg={{ span: 3, offset: 3 }} xs={6}>
                                <img className="img-fluid mobile-small" alt="Rocketx" src={ImgPartnerRocketx} width="130" />
                            </Col>
                            <Col className="backer-logo py-2" lg={3} xs={6}>
                                <img className="img-fluid mobile-small" alt="Maticlaunch" src={ImgPartnerMaticLaunch} width="115" />
                            </Col>

                        </Row>
                    </div>
                    <div className='multichain-section pb-5'>
                        <div className="section-header">
                            <h6 className="section-sub-title mb-0">Integration<span className="brand-color">.</span></h6>
                            <h1 className="section-title brand-color-white title-right-grill text-center pt-5 font-bold">Chains Integrated<span className="brand-color">.</span></h1>
                        </div>
                        <Row className="content-padding pb-5 text-center">
                            <Col className=" py-4" lg={3} xs={6}>
                                <img className="img-fluid " alt="binance smart chain" src="../images/chains/bsc_logo.png" width="180" />
                            </Col>
                            <Col className=" py-4" lg={3} xs={6}>
                                <img className="img-fluid " alt="eth logo" src="../images/chains/eth_logo.png" width="180" />
                            </Col>
                            <Col className=" py-4" lg={3} xs={6}>
                                <img className="img-fluid " alt="Polygon logo" src={ImgChainPolygon} width="180" />
                            </Col>
                            <Col className=" py-4" lg={3} xs={6}>
                                <img className="img-fluid " alt="KCC logo" src={ImgChainKCC} width="150" />
                            </Col>
                            <Col className=" py-4" lg={{ span: 3, offset: 3 }} xs={6} >
                                <img className="img-fluid " alt="KCC logo" src={ImgChainAvax} width="180" />
                            </Col>
                            <Col className=" py-4" lg={3} xs={6}>
                                <img className="img-fluid " alt="KCC logo" src={ImgChainOex} width="95" />
                            </Col>

                            {/* <Col className=" py-4" lg={3} sm={6}>
                                <img className="img-fluid " alt="huobieco logo" src="../images/chains/huobieco_logo.png" width="180" />
                            </Col>
                            <Col className=" py-4" lg={3} sm={6}>
                                <img className="img-fluid " alt="polkadot logo" src="../images/chains/polkadot_logo.svg" width="180" />
                            </Col>
                            <Col className=" py-4" lg={3} sm={6}>
                                <img className="img-fluid " alt="cardano logo" src="../images/chains/cardano_logo.svg" width="180" />
                            </Col>
                            <Col className=" py-4" lg={3} sm={6}>
                                <img className="img-fluid " alt="dfinity logo" src="../images/chains/dfinity_logo.svg" width="180" />
                            </Col> */}
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    )
}


export default Home