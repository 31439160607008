import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { approve } from '../utils/callHelpers'
import { getLpVaultAddress, getVestingVaultAddress } from '../utils/addressHelpers'
import { getTokenContract } from '../utils/contractHelpers'
import { toast } from 'react-toastify'

export const useVaultApprove = () => {
    const { account, library, chainId } = useWeb3React()

    const handleApprove = useCallback(async (tokenAddress, type: 'token' | 'pair') => {
        try {
            if (!library || !account || !chainId) return false
            console.log("called")
            let tokenContract = getTokenContract(tokenAddress, library, account)
            if (!tokenContract) return false
            console.log(tokenContract)
            const tx = await approve(
                tokenContract,
                type === 'token'
                    ? getVestingVaultAddress(chainId)
                    : getLpVaultAddress(chainId), account)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onApprove: handleApprove }
}