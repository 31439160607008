import { useWeb3React } from '@web3-react/core';
import React, { useState, FunctionComponent, useEffect, useCallback } from 'react';
import { Container, Col, Row, Form, Dropdown, Spinner } from 'react-bootstrap';
import BigNumber from 'bignumber.js'
import './create-lock.scss';
import { isAddress } from '@ethersproject/address'
import { useToken } from '../../hooks/Tokens';
import { getBalanceNumber, getFullDisplayBalance } from '../../utils/formatBalance';
import { getCurrencyName, getExplorerName, getExplorerUrl, getPurposeName } from '../../utils';
import { VAULT_PURPOSE_MAPPING } from '../../constants'
import { add, sub } from 'date-fns'
import { useCreateLock, useCreateMultipleLock, useFees, usePairCheck, useVestingDiscount } from '../../hooks/VestingVault';
import { useVaultApprove } from '../../hooks/useApprove';
import { useAllowance } from '../../hooks/useAllowance';
import PageHeader from '../../components/PageHeader';
import VestingTable from '../../components/VestingTable'
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
// import { useHistory } from 'react-router-dom';

type StepperItemType = {
    id: number;
    title: string;
}

type RadioItemProps = {
    title: string;
    description: string;
    iconSrc: string;
    value: string;
    onClick: Function;
    isSelected?: boolean;
}

const stepperData: StepperItemType[] = [{
    id: 1,
    title: 'Select Blockchain'
}, {
    id: 2,
    title: 'Enter Your Address'
}, {
    id: 3,
    title: 'Confirm Your Token'
}, {
    id: 4,
    title: 'Locking Token For'
}, {
    id: 5,
    title: 'Finalize Your Lock'
}, {
    id: 6,
    title: 'Track Your Lock'
}]

const RadioItem: FunctionComponent<RadioItemProps> = (data) => {
    return (
        <>
            <div className="radio-item-container d-flex justify-content-between align-items-center mb-2" onClick={() => data.onClick(data.value)}>
                <div className="detail-container p-0 d-flex justify-content-between align-items-center">
                    <div className="img-container">
                        <img alt={data.title} className="chain-icon" src={data.iconSrc} />
                    </div>
                    <div className="">
                        <h5 className="mb-0 font-bold">{data.title}</h5>
                        <p className="mb-0">{data.description}</p>
                    </div>
                </div>
                <div className="radio-container">
                    <div className={"radio-shape" + (data.isSelected ? " radio-selected" : "")}></div>
                </div>
            </div>
        </>
    )
}

const CreateVestingLock = (props: any) => {

    const { active, chainId, error, account } = useWeb3React()

    const [selectedPurpose, setSelectedPurpose] = useState(-1)
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedChain, setSelectedChain] = useState('bsc');
    const [selectedTokenType, setSelectedTokenType] = useState<'token' | 'pair'>('token');
    const [tokenAddress, setTokenAddress] = useState('');
    const [withdrawType, setWithdrawType] = useState<'myself' | 'other'>('myself');
    const [withdrawAddress, setWithdrawAddress] = useState('');
    const [selectedDurationType, setSelectedDurationType] = useState<'Minutes' | 'Days' | 'Month'>('Days');
    const [selectedVestingDuration, setSeletctedVestingDuration] = useState<7 | 14 | 30 | 60 | 90 | 180 | 365>(30);
    const [vestingLockCount, setVestingLockCount] = useState(2)
    const [selectedLockType, setSelectedLockType] = useState<'singleLock' | 'linerVesting'>('singleLock')
    const [cliff, setCliff] = useState(false)
    const [cliffMonth, setCliffMonth] = useState(0);
    const [lockAmount, setLockAmount] = useState(0);
    const [vestingTableShow, setVestingTableShow] = useState(false)
    const [lockDuration, setLockDuration] = useState(365);
    const [unlockTimestamp, setUnlockTimestamp] = useState<number>();
    const [isUnlockModifyOpen, setIsUnlockModifyOpen] = useState(false);
    const [firstUnlockTimeStamp, setFirstUnlockTimeStamp] = useState<number>((new Date()).setUTCHours(24, 0, 0, 0));
    const [multpleUnlockTimestamp, setMultipleUnlockTimestamp] = useState<number[]>([]);
    const [requestedApproval, setRequestedApproval] = useState(false)
    const [lockRequested, setLockRequested] = useState(false)
    const [approveTxhash, setApproveTxhash] = useState('')
    const [lockTxhash, setLockTxhash] = useState('')
    const [formValidity, setFormValidity] = useState<'INVLAID_AMOUNT' | 'INVALID_VESTING_COUNT' | 'INVALID_VESTING_DURATION' | 'INVALID_DURATION' | 'VALID'>('INVLAID_AMOUNT')
    const [calculatedFees, setCalculatedFees] = useState('')
    let token = useToken(tokenAddress, selectedTokenType)
    const fees = useFees()
    const vestingDiscount = useVestingDiscount()
    const allowance = useAllowance(tokenAddress, selectedTokenType)
    const { onApprove } = useVaultApprove()
    const { onHandleLock } = useCreateLock()
    const { onHandleMultipleLock } = useCreateMultipleLock()
    const { isPairCheck } = usePairCheck()
    // const history = useHistory();
    // let { lockType } = useParams<{ lockType: string }>();

    let explorerUrl = getExplorerUrl(chainId)

    // useEffect(() => {
    //     // console.log(props.location.search)
    //     let lockType = new URLSearchParams(props.location.search).get("type")
    //     console.log(lockType)
    //     let type: 'token' | 'pair' = 'token'
    //     if(lockType === 'lp-vault') {
    //         type = 'pair'
    //     } else if (lockType === 'vesting') {
    //         type = 'token'
    //     } else {
    //         //redirect to vault page 
    //         history.push("/vault")
    //     }
    //     setSelectedTokenType(type)
    // }, [])

    useEffect(() => {
        console.log("error called")
        if (error) {
            window.location.reload()
        }
    }, [error])

    useEffect(() => {
        console.log("conditional timestamp called")
        if (selectedLockType == 'singleLock') {
            calculateUnlockTimeStamp()
        } else if (selectedLockType == 'linerVesting') {
            calculateMultipleUnlockTimeStamp()
        }
    }, [lockDuration, selectedDurationType, firstUnlockTimeStamp])

    useEffect(() => {
        console.log("MultipleUnlockTimeStamp called")
        calculateMultipleUnlockTimeStamp()
    }, [vestingLockCount, selectedVestingDuration, cliffMonth, cliff, firstUnlockTimeStamp])

    useEffect(() => {
        console.log("withdrawType called")
        if (withdrawType === 'myself') {
            setWithdrawAddress(account ? account : '')
        }
    }, [withdrawType, account])

    useEffect(() => {
        console.log("validity called")
        let balance = token && token.balance && token.decimals && getBalanceNumber(token.balance, token.decimals)
        if (!balance || lockAmount <= 0 || balance < lockAmount) {
            setFormValidity('INVLAID_AMOUNT')
        } else if (selectedLockType === 'linerVesting' && ((cliffMonth * 30) + (selectedVestingDuration * vestingLockCount) > 1080)) {
            setFormValidity('INVALID_VESTING_DURATION')
        } else if (selectedLockType === 'linerVesting' && vestingLockCount < 2) {
            setFormValidity('INVALID_VESTING_COUNT')
        } else if (selectedLockType === 'singleLock' && (lockDuration < 0 || unlockTimestamp! < Date.now())) {
            setFormValidity('INVALID_DURATION')
        } else if (selectedLockType === 'linerVesting' && (multpleUnlockTimestamp[0] * 1000 < Date.now())) {
            console.log(multpleUnlockTimestamp, Date.now())
            setFormValidity('INVALID_DURATION')
        } else {
            setFormValidity('VALID')
        }
        console.log(formValidity, selectedVestingDuration * vestingLockCount)
    }, [lockDuration, lockAmount, unlockTimestamp, selectedLockType, selectedVestingDuration, vestingLockCount, cliffMonth, multpleUnlockTimestamp])

    useEffect(() => {
        console.log("calculation called")
        const unit = getCurrencyName(chainId)
        if (selectedLockType === 'singleLock') {
            setCalculatedFees(fees ?? '0' + ' ' + unit)
        } else {
            const vestingFees = (new BigNumber(fees ?? 0).multipliedBy(vestingLockCount * (100 - (vestingDiscount ?? 0)) / 100)).toString()
            setCalculatedFees(vestingFees + ' ' + unit)
        }
    }, [vestingLockCount, fees, selectedLockType])

    useEffect(() => {

    }, [tokenAddress, selectedTokenType, chainId, account])




    const showFormValidityToast = function (formValidity: any) {
        switch (formValidity) {
            case 'INVALID_DURATION':
                toast.error('Please enter valid duration')
                break;
            case 'INVLAID_AMOUNT':
                toast.error('Please enter valid token amount')
                break;
            case 'INVALID_VESTING_DURATION':
                toast.error('Total vesting period should be less than 36 months')
                break;
            case 'INVALID_VESTING_COUNT':
                toast.error('Please use Single lock feature if it is not vesting')
                break;
        }
    }

    const handleApprove = useCallback(async (tokenAddress, token) => {
        try {
            if (requestedApproval) return
            if (formValidity !== 'VALID') {
                showFormValidityToast(formValidity)
                return
            }
            setRequestedApproval(true)
            let tx = await onApprove(tokenAddress, selectedTokenType)
            if (tx) {
                toast.success("Approve request has been submitted")
                setApproveTxhash(tx.hash)
            } else {
                setRequestedApproval(false)
            }
        } catch (e: any) {
            setRequestedApproval(false)
            toast.error(e.message)
        }
    }, [onApprove, formValidity])

    const handleCreateLock = useCallback(async (tokenAddress) => {
        try {
            if (!token || lockRequested) return
            if (formValidity !== 'VALID') {
                showFormValidityToast(formValidity)
                return
            }
            setLockRequested(true)
            let tx = undefined;
            if (selectedLockType === 'singleLock') {
                tx = await onHandleLock(
                    tokenAddress,
                    withdrawAddress,
                    new BigNumber(lockAmount).times(new BigNumber(10).pow(token?.decimals).toString()).decimalPlaces(0, BigNumber.ROUND_FLOOR).toString(),
                    Math.round(unlockTimestamp! / 1000),
                    fees!,
                    selectedPurpose)
            } else if (selectedLockType === 'linerVesting') {
                // alert(new BigNumber(lockAmount / vestingLockCount).times(
                //     new BigNumber(10).pow(token?.decimals).toString()
                // ).decimalPlaces(0, BigNumber.ROUND_FLOOR)
                // .toString())
                tx = await onHandleMultipleLock(
                    tokenAddress,
                    withdrawAddress,
                    Array(vestingLockCount).fill(
                        new BigNumber(lockAmount / vestingLockCount).times(
                            new BigNumber(10).pow(token?.decimals).toString()
                        ).decimalPlaces(0, BigNumber.ROUND_FLOOR)
                            .toString()),
                    multpleUnlockTimestamp,
                    fees && (new BigNumber(fees).multipliedBy(vestingLockCount).multipliedBy((100 - (vestingDiscount ?? 0)) / 100)).toString(),
                    selectedPurpose
                )
            }

            setLockRequested(false)
            if (tx) {
                setLockTxhash(tx.hash)
                setCurrentStep(6)
            }

        } catch (e: any) {
            toast.error(e.message)
        }
    }, [onHandleLock])

    useEffect(() => {
        if (allowance?.comparedTo(lockAmount) === 1) {
            setRequestedApproval(false)
        }
    }, [allowance])

    const isApproveDoneOrInProgress = function () {
        if (allowance?.comparedTo(lockAmount) === 1 || requestedApproval) {
            // setRequestedApproval(false)
            return true
        } else {
            return false
        }
    }

    const isApproveDone = function () {
        if (allowance?.comparedTo(lockAmount) === 1) {
            return true
        } else {
            return false
        }
    }

    const handleNextClick = async () => {
        if (!active && !chainId) {
            toast.error('Please connect your wallet to continue ');
            return
        }

        switch (currentStep) {
            case 1:
                if (selectedChain === 'bsc' && ![97, 56].includes(chainId!)) {
                    toast.error("Please set BSC in your wallet");
                    return
                }
                if (selectedChain === 'eth' && ![3, 1].includes(chainId!)) {
                    toast.error("Please set ETH in your wallet");
                    return
                }
                if (selectedChain === 'matic' && ![80001, 137].includes(chainId!)) {
                    toast.error("Please set Polygon in your wallet");
                    return
                }
                if (selectedChain === 'kcc' && ![322, 321].includes(chainId!)) {
                    toast.error("Please set KCC in your wallet");
                    return
                }
                if (selectedChain === 'avax' && ![43114, 43113].includes(chainId!)) {
                    toast.error("Please set AVAX in your wallet");
                    return
                }
                if (selectedChain === 'oec' && ![66, 65].includes(chainId!)) {
                    toast.error("Please set OEC in your wallet");
                    return
                }
                if (selectedChain === 'optimism' && ![10, 420].includes(chainId!)) {
                    toast.error("Please set Optimism in your wallet");
                    return
                }
                if (selectedChain === 'arbitrum' && ![42161, 421613].includes(chainId!)) {
                    toast.error("Please set Arbitrum in your wallet");
                    return
                }
                break;
            // case 2:
            //     break;
            case 2:
                if (!isAddress(tokenAddress) || !token) {
                    return
                }
                let res = await isPairCheck(tokenAddress)
                if (res) {
                    toast.error("Please use LP vault to lock liquidity");
                    return
                }
                break;
            case 3:
                break;
            case 4:
                if (selectedPurpose === -1) {
                    toast.error("Please select purpose of you locking the token")
                    return
                }
                break;
        }

        if (currentStep !== stepperData.length) {
            setCurrentStep(currentStep + 1)
        }
    }

    function calculateUnlockTimeStamp() {
        // console.log(firstUnlockTimeStamp)
        // if (firstUnlockTimeStamp && firstUnlockTimeStamp > Date.now()) {
        //     setUnlockTimestamp(firstUnlockTimeStamp)
        //     console.log("set")
        //     return
        // }
        let date = new Date();
        let duration = {}
        if (selectedDurationType === 'Days') {
            duration = {
                'days': lockDuration
            }
        } else if (selectedDurationType === 'Month') {
            duration = {
                'months': lockDuration
            }
        } else if (selectedDurationType === 'Minutes') {
            duration = {
                'minutes': lockDuration
            }
        }
        let unlockDate = add(date, duration)
        setUnlockTimestamp(unlockDate.getTime())
    }

    function calculateMultipleUnlockTimeStamp() {
        //drife - fix
        // let date = new Date("09/14/2021");
        //MTCL - fix
        // let date = new Date("08/30/2021");
        let date = new Date()
        date.setUTCHours(24, 0, 0, 0)
        if (firstUnlockTimeStamp && firstUnlockTimeStamp > 0) {
            date = new Date(firstUnlockTimeStamp)
        }
        if (firstUnlockTimeStamp < Date.now()) {
            // date = new Date()
            // setFirstUnlockTimeStamp(Date.now())
        }
        // let date = new Date();

        let multipleUnlockTimeStamp = []
        if (vestingLockCount < 100) {
            for (let i = 0; i < vestingLockCount; i++) {
                if (cliff && i === 0) {
                    date = add(date, {
                        days: selectedVestingDuration,
                        months: cliffMonth
                    })
                } else {
                    date = add(date, {
                        'days': selectedVestingDuration
                    })
                }

                multipleUnlockTimeStamp.push(Math.round(date.getTime() / 1000))
            }
            console.log(multipleUnlockTimeStamp)
            setMultipleUnlockTimestamp(multipleUnlockTimeStamp)
        } else {
            setMultipleUnlockTimestamp([])
        }

    }

    function handleBackClick() {
        if (currentStep !== 1)
            setCurrentStep(currentStep - 1)
    }

    function changeChain(chain: 'bsc' | 'eth' | 'matic' | 'kcc' | 'avax' | 'oec' | 'optimism' | 'arbitrum') {
        setSelectedChain(chain)
    }

    function changeTokenType(type: 'token' | 'pair') {
        setTokenAddress('')
        if (type === 'token') {
            setSelectedPurpose(-1)
        } else {
            setSelectedPurpose(99)
        }
        setSelectedTokenType(type)
    }

    function setLockAmountToMax() {
        let balance = token && token.balance && token.decimals && getBalanceNumber(token.balance, token.decimals)
        if (balance) {
            setLockAmount(balance)
        } else {
            setLockAmount(0)
        }
    }

    function getTokenName(chainName: string) {
        if (chainName === 'matic' || chainName === 'eth') {
            return 'ERC-20'
        }
        if (chainName === 'bsc') {
            return 'BEP-20'
        }
        if (chainName === 'kcc') {
            return 'KRC-20'
        }
    }

    function getLpProviderName(chainName: string) {
        if (chainName === 'eth') {
            return 'Uniswap'
        }
        if (chainName === 'matic') {
            return 'Quickswap'
        }
        if (chainName === 'bsc') {
            return 'Pancakeswap'
        }
        if (chainName === 'kcc') {
            return 'Koffeswap'
        }
    }

    return (
        <>
            <Container fluid className="create-vesting-page brand-bgcolor-shadow-black pl-0 pr-0 pb-5 brand-color-white">
                <PageHeader title='Lock Process' />
                <Row className="create-lock-form-content content-padding">
                    <Col className="left-section" lg={4}>
                        {stepperData.map((obj, index) => (
                            <div className="d-flex justify-content-between align-items-center step-title-item ">
                                <h5 className="font-bold mb-0">
                                    {obj.title}
                                </h5>
                                <h5 className={"number-normal ml-3 font-bold text-center mb-0" + (obj.id === currentStep ? " number-hightlight" : "")}>
                                    {obj.id}.
                                </h5>
                            </div>
                        ))}

                    </Col>
                    <Col className="right-section" lg={8}>
                        {currentStep === 1 && (
                            <div className="step select-blockchain">
                                <h4 className="font-bold step-title">Select Blockchain</h4>
                                <div className="blockchain-list">
                                    <RadioItem
                                        iconSrc="../../images/vault-bsc.svg"
                                        title="Binance Smart Chain"
                                        description="Select if your coin is an BEP-20 token"
                                        value="bsc"
                                        onClick={changeChain}
                                        isSelected={selectedChain === 'bsc'}
                                    />
                                    <RadioItem
                                        iconSrc="../../images/vault-polygon.svg"
                                        title="Polygon (Matic)"
                                        description="Select if your coin is an ERC-20 token"
                                        value="matic"
                                        onClick={changeChain}
                                        isSelected={selectedChain === 'matic'}
                                    />
                                    <RadioItem
                                        iconSrc="../../images/vault-avax.svg"
                                        title="Avalanche C-Chain"
                                        description="Select if your coin is an ERC-20 token"
                                        value="avax"
                                        onClick={changeChain}
                                        isSelected={selectedChain === 'avax'}
                                    />
                                    <RadioItem
                                        iconSrc="../../images/vault-oec.svg"
                                        title="OEC (OKX Chain)"
                                        description="Select if your coin is an KIP-20 token"
                                        value="oec"
                                        onClick={changeChain}
                                        isSelected={selectedChain === 'oec'}
                                    />
                                    <RadioItem
                                        iconSrc="../../images/vault-eth.svg"
                                        title="Ethereum"
                                        description="Select if your coin is an ERC-20 token"
                                        value="eth"
                                        onClick={changeChain}
                                        isSelected={selectedChain === 'eth'}
                                    />
                                    <RadioItem
                                        iconSrc="../../images/vault-kcc.svg"
                                        title="KuCoin Community chain"
                                        description="Select if your coin is an KRC-20 token"
                                        value="kcc"
                                        onClick={changeChain}
                                        isSelected={selectedChain === 'kcc'}
                                    />
                                    <RadioItem
                                        iconSrc="../../images/vault-optimism.svg"
                                        title="Optimism"
                                        description="Select if your coin is an ERC-20 token"
                                        value="optimism"
                                        onClick={changeChain}
                                        isSelected={selectedChain === 'optimism'}
                                    />
                                    <RadioItem
                                        iconSrc="../../images/vault-arbitrum.svg"
                                        title="Arbitrum"
                                        description="Select if your coin is an ERC-20 token"
                                        value="arbitrum"
                                        onClick={changeChain}
                                        isSelected={selectedChain === 'arbitrum'}
                                    />
                                    
                                </div>
                                <div className="d-flex justify-content-between btn-container">
                                    <div></div>
                                    <button className="btn btn-brand" onClick={handleNextClick}>NEXT</button>
                                </div>

                            </div>
                        )}
                        {/* {currentStep === 2 && (
                            <div className="step select-lock-type">
                                <h4 className="font-bold step-title">Choose Lock Type</h4>
                                <RadioItem
                                    iconSrc="../../images/vault-token.svg"
                                    title="Project Tokens"
                                    description={"Your native " + getTokenName(selectedChain) + " token built on " + (selectedChain.toLocaleUpperCase())}
                                    value="token"
                                    onClick={changeTokenType}
                                    isSelected={selectedTokenType === 'token'}
                                />
                                <RadioItem
                                    iconSrc="../../images/vault-eth.svg"
                                    title="Liquidity Tokens"
                                    description={"LP tokens minted from " + getLpProviderName(selectedChain) + " Pool"}
                                    value="pair"
                                    onClick={changeTokenType}
                                    isSelected={selectedTokenType === 'pair'}
                                />
                                <div className="d-flex justify-content-between btn-container">
                                    <button className="btn btn-brand-dark" onClick={handleBackClick}>BACK</button>
                                    <button className="btn btn-brand" onClick={handleNextClick}>NEXT</button>
                                </div>
                            </div>
                        )} */}
                        {currentStep === 2 && (
                            <div className="step enter-address">
                                <h4 className="font-bold step-title">Enter Your Address</h4>
                                <div className="mt-3">
                                    <h5>Enter your token address you would like to lock for</h5>
                                    <Form.Group>
                                        <Form.Control
                                            className="mb-1 address-field"
                                            type="text"
                                            placeholder="Enter address"
                                            value={tokenAddress}
                                            onChange={({ target: { value } }) => {
                                                setTokenAddress(value)
                                            }}
                                            isInvalid={Boolean(tokenAddress) && ((!isAddress(tokenAddress)) || (token === undefined))}
                                        />
                                        {Boolean(tokenAddress) && isAddress(tokenAddress) && (token === null) && (
                                            <div className="token-address-spinner-container">
                                                <Spinner animation="grow" />
                                            </div>)}
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter vaild contract address
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <p className="brand-color-grey text-truncate">Example: 0xd5e950837Ad48D08baD2f87bFcF8eD7167bB44BC</p>
                                </div>

                                <div className="d-flex justify-content-between btn-container">
                                    <button className="btn btn-brand-dark" onClick={handleBackClick}>BACK</button>
                                    <button className="btn btn-brand" disabled={Boolean(!token)} onClick={handleNextClick}>
                                        CONTINUE
                                    </button>
                                </div>
                            </div>
                        )}
                        {currentStep === 3 && (
                            <div className="step token-conformation">
                                <h4 className="font-bold step-title">Confirm Your Token</h4>
                                <div>
                                    <div className="mb-4 d-flex align-items-center">
                                        <Form.Control disabled={true} className="address-field address-field-viewonly" type="text" value={tokenAddress} />
                                        <a className="view-token-explorer" href={explorerUrl + '/token/' + token?.tokenAddress} target="_blank" rel="noreferrer">View</a>
                                    </div>
                                    <div>
                                        <h4 className="font-bold">Token Found:</h4>
                                        <table className="token-overview-table" cellSpacing="10px">
                                            <tbody>
                                                <tr>
                                                    <td className="title font-bold">Token Name</td>
                                                    <td className="value">{token?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="title font-bold">Token Ticker</td>
                                                    <td className="value">{token?.symbol}</td>
                                                </tr>
                                                <tr>
                                                    <td className="title font-bold">Total Supply</td>
                                                    <td className="value">{token && getBalanceNumber(token?.totalSupply, token.decimals)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="title font-bold">Your Balance</td>
                                                    <td className="value">{token && token?.balance && getBalanceNumber(token?.balance, token.decimals)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between btn-container">
                                    <button className="btn btn-brand-dark" onClick={handleBackClick}>BACK</button>
                                    <button className="btn btn-brand" onClick={handleNextClick}>Confirm</button>
                                </div>
                            </div>
                        )}
                        {currentStep === 4 && (
                            <div className="step locking-token-for">
                                <h4 className="font-bold step-title">I am Locking Tokens for</h4>
                                <div>
                                    <div className="withdrawal-container">
                                        <img className={"status-arrow" + ((isAddress(withdrawAddress)) ? ' status-arrow-active' : '')} src={`../../images/vault-arrow${(isAddress(withdrawAddress)) ? '-selected' : ''}.svg`} />
                                        <div className="withdrawal-select mt-4">
                                            <div className="withdrawal-switch d-flex">
                                                <div className={"switch-selector switch-selector-myself" + (withdrawType === 'myself' ? ' switch-selector-active' : '')}
                                                    onClick={() => {
                                                        setWithdrawType('myself')
                                                        setWithdrawAddress(account ? account : '')
                                                    }}>
                                                    Myself
                                                </div>
                                                <div className={"switch-selector switch-selector-others" + (withdrawType === 'other' ? ' switch-selector-active' : '')}
                                                    onClick={() => {
                                                        setWithdrawType('other')
                                                        setWithdrawAddress('')
                                                    }}>
                                                    Someone Else
                                                </div>
                                            </div>
                                            <div>
                                                <Form.Group>
                                                    <Form.Control
                                                        className="mb-1 address-field mt-3"
                                                        type="text"
                                                        placeholder="Enter withdrawal address"
                                                        value={withdrawAddress}
                                                        onChange={({ target: { value } }) => {
                                                            setWithdrawAddress(value)
                                                        }}
                                                        disabled={withdrawType === 'myself'}
                                                        isInvalid={Boolean(withdrawAddress) && (!isAddress(withdrawAddress))}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter vaild wallet address
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedTokenType === 'token' && (
                                        <div className="purpose-select-container mt-4">
                                            <img className={"status-arrow" + ((selectedPurpose !== -1) ? ' status-arrow-active' : '')} src={`../../images/vault-arrow${(selectedPurpose !== -1) ? '-selected' : ''}.svg`} />
                                            <div className="purpose-select d-flex">
                                                {VAULT_PURPOSE_MAPPING.map((item: any, index) => (
                                                    <div className={"purpose-item" + (selectedPurpose === item.id ? " purpose-item-active" : "")}
                                                        key={'purpose' + index}
                                                        onClick={() => {
                                                            setSelectedPurpose(item.id)
                                                        }}>{item.name}</div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between btn-container">
                                    <button className="btn btn-brand-dark" onClick={handleBackClick}>BACK</button>
                                    <button className="btn btn-brand" onClick={handleNextClick}>Next</button>
                                </div>
                            </div>
                        )}
                        {currentStep === 5 && (
                            <div className="step lock-token">
                                <div className="d-flex justify-content-between">
                                    <h4 className="font-bold step-title">Finalize Your Lock</h4>
                                    <div>
                                        {selectedTokenType === 'token' && <div className="mb-3 lock-type-selector">
                                            <Form.Check custom inline label="Single Lock" name="lockVesting" type="radio" id={`inline-radio-1`} checked={selectedLockType === 'singleLock'} onChange={() => {
                                                setSelectedLockType('singleLock')
                                            }} />
                                            <Form.Check custom inline label="Linear Vesting" name="lockVesting" type="radio" id={`inline-radio-2`} onChange={() => {
                                                setSelectedLockType('linerVesting')
                                            }} />
                                        </div>}
                                    </div>
                                </div>

                                <div className="lock-detail-section">
                                    <div className="lock-amout text-center">
                                        <Row className="mb-2 align-items-center">
                                            <Col lg={6} xs={6} className="py-2 pl-4">
                                                <Form.Group className="mb-0">
                                                    <Form.Label>Lock Amount</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        // defaultValue={lockAmount}
                                                        value={lockAmount}
                                                        min={0}
                                                        // isInvalid={isInvalidAmount()}
                                                        // onKeyUpCapture = { return event.charCode >= 48}
                                                        // onkeypress="return event.charCode >= 48"
                                                        onChange={({ target: { value } }) => {
                                                            setLockAmount(Number(value))
                                                        }}
                                                        onWheel={(event: any) => event.currentTarget.blur()}
                                                    />
                                                    {/* <Form.Control.Feedback type="invalid">
                                                        Please Enter vaild amount
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>
                                            </Col>
                                            <div className="floting-max-btn-container">
                                                <button className="btn floting-max-btn" onClick={setLockAmountToMax}>MAX</button>
                                            </div>
                                            <Col lg={6} xs={6} className="py-2 pr-4">
                                                <Form.Group className="mb-0">
                                                    <Form.Label>Balance: {token && token?.balance && getBalanceNumber(token?.balance, token.decimals)}</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        value={token?.symbol}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                    {selectedLockType === 'singleLock' && (<div className="lock-duration text-center mb-4">
                                        <Row className="align-items-center">
                                            <Col lg={6} xs={6} className="py-2 pl-4">
                                                <Form.Group className="mb-0">
                                                    <Form.Label>Locking Duration</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        // defaultValue="365"
                                                        min="0"
                                                        value={Number(lockDuration)}
                                                        // isInvalid={lockDuration < 0 || unlockTimestamp! < Date.now()}
                                                        onChange={({ target: { value } }) => {
                                                            let val = Math.round(Number(value))
                                                            setLockDuration(val)
                                                        }}
                                                        onWheel={(event: any) => event.currentTarget.blur()}
                                                    />
                                                    {/* <Form.Control.Feedback type="invalid">
                                                        Please Enter Vaild Duration
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} xs={6} className="py-2 pr-4">
                                                <Form.Group className="mb-0">
                                                    <Form.Label>Time Scale</Form.Label>
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            {selectedDurationType}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                setSelectedDurationType('Minutes')
                                                            }}>Minutes</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                setSelectedDurationType('Days')
                                                            }}>Days</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                setSelectedDurationType('Month')
                                                            }}>Month</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>)}
                                    {selectedLockType === 'linerVesting' && (<div className="mb-3">
                                        <div className="lock-duration text-center">
                                            <Row className="align-items-center">
                                                <Col lg={6} xs={6} className="py-2 pl-4">
                                                    <Form.Group className="mb-0">
                                                        <Form.Label>Every Unlock in</Form.Label>
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                {selectedVestingDuration} Days
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {[7, 14, 30, 60, 90, 180, 365].map((item: any, index) => (
                                                                    <Dropdown.Item key={'vesting-duration' + index} onClick={() => {
                                                                        setSeletctedVestingDuration(item)
                                                                    }}>{item} Days</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Form.Group>
                                                </Col>
                                                <div className={"floting-cliff-container" + (cliff === true ? ' cliff-input-active' : '')} >
                                                    {cliff === false && (
                                                        <button className="btn floting-cliff-btn" onClick={() => {
                                                            setCliff(true)
                                                        }}>CLIFF</button>
                                                    )}
                                                    {cliff === true && (
                                                        <div className="d-flex flex-column align-items-center">
                                                            <Form.Group className="mb-0">
                                                                <Form.Label>CLIFF <span className="unit-text mb-0">(Month)</span></Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="number"
                                                                    className="cliff-input"
                                                                    // defaultValue={lockAmount}
                                                                    value={cliffMonth}
                                                                    min={0}
                                                                    // isInvalid={isInvalidAmount()}
                                                                    // onKeyUpCapture = { return event.charCode >= 48}
                                                                    // onkeypress="return event.charCode >= 48"
                                                                    onChange={({ target: { value } }) => {
                                                                        setCliffMonth(Math.round(Number(value)))
                                                                    }}
                                                                    onWheel={(event: any) => event.currentTarget.blur()}
                                                                />
                                                            </Form.Group>
                                                            {/* <p className="mb-0 unit-text">In Months</p> */}
                                                        </div>
                                                    )}
                                                </div>
                                                {/* <Col lg={2} xs={6} className="py-2 pr-4">
                                                    
                                                </Col> */}
                                                <Col lg={6} xs={6} className="py-2 pr-4">
                                                    <Form.Group className="mb-0">
                                                        <Form.Label>Lock Count</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            // defaultValue={lockAmount}
                                                            value={vestingLockCount}
                                                            min={0}
                                                            // isInvalid={isInvalidAmount()}
                                                            // onKeyUpCapture = { return event.charCode >= 48}
                                                            // onkeypress="return event.charCode >= 48"
                                                            onChange={({ target: { value } }) => {
                                                                setVestingLockCount(Math.round(Number(value)))
                                                            }}
                                                            onWheel={(event: any) => event.currentTarget.blur()}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid">
                                                                Please Enter vaild amount
                                                            </Form.Control.Feedback> */}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        {selectedLockType === 'linerVesting' && (
                                            <div className="d-flex justify-content-between recipt-item align-items-center recipt-item-unlock-start">
                                                <h6 className="mb-0 mr-3">UNLOCK START TIME :</h6>
                                                <div className="mb-0 text-right d-flex align-items-center">
                                                    {isUnlockModifyOpen && unlockTimestamp && (
                                                        <>
                                                            <DatePicker
                                                                minDate={sub(new Date(), {
                                                                    'days': selectedVestingDuration - 1,
                                                                    'months': cliffMonth ?? 0
                                                                })}
                                                                dateFormat="dd MMM yyyy h:mm aa zz"
                                                                disabledKeyboardNavigation
                                                                showTimeSelect
                                                                selected={firstUnlockTimeStamp ? new Date(firstUnlockTimeStamp) : new Date()}
                                                                onChange={(date) => {
                                                                    console.log(date?.valueOf())
                                                                    setFirstUnlockTimeStamp(Number(date?.valueOf())!)
                                                                }}
                                                            />
                                                        </>
                                                    )}

                                                    {!isUnlockModifyOpen && (
                                                        <h6 className="mb-0">{new Date(firstUnlockTimeStamp).toUTCString().replace('GMT', 'UTC')}</h6>
                                                    )}
                                                    {selectedLockType === 'linerVesting' && (<h6 className="mb-0 ml-2 edit-first-unlock" onClick={(e) => {
                                                        e.preventDefault();
                                                        setIsUnlockModifyOpen(!isUnlockModifyOpen)
                                                    }
                                                    }>{!isUnlockModifyOpen ? 'Customize' : 'Done'}</h6>)}
                                                </div>

                                            </div>
                                        )}
                                        {vestingLockCount > 0 && lockAmount > 0 && (
                                            <div className="vesting-statment justify-content-between ">
                                                <h6 className="mb-0">

                                                    {cliff && cliffMonth > 0 && (
                                                        <>
                                                            After <span className="brand-color">{cliffMonth} Months, </span>
                                                        </>
                                                    )}
                                                    You will be able to claim <span className="brand-color">{(Math.floor(lockAmount / vestingLockCount * 10000) / 10000)} {token?.symbol} </span>
                                                    every <span className="brand-color">{selectedVestingDuration} Days</span>


                                                </h6>
                                                <h6 className="mb-0 view-vesting-table" onClick={() => {
                                                    if (formValidity !== 'VALID') {
                                                        showFormValidityToast(formValidity)
                                                        return
                                                    }
                                                    setVestingTableShow(true)
                                                }}>
                                                    View Vesting Table
                                                </h6>
                                            </div>
                                        )}
                                    </div>
                                    )}
                                    <div className="recipt-view">
                                        <div className="d-flex justify-content-between recipt-item">
                                            <h6 className="mb-0">TOTAL LOCKED</h6>
                                            <h6 className="mb-0 brand-color">{lockAmount} {token?.symbol}</h6>
                                        </div>
                                        <div className="d-flex justify-content-between recipt-item align-items-center">
                                            <h6 className="mb-0">{selectedLockType === 'linerVesting' ? 'FIRST ' : ''}UNLOCK TIME</h6>
                                            <div className="mb-0 text-right d-flex align-items-center">
                                                {(
                                                    <h6 className="mb-0">{(unlockTimestamp
                                                        && selectedLockType === 'singleLock' && new Date(unlockTimestamp).toUTCString().replace('GMT', 'UTC'))
                                                        || selectedLockType === 'linerVesting' && new Date(multpleUnlockTimestamp[0] * 1000).toUTCString().replace('GMT', 'UTC')}</h6>
                                                )}
                                            </div>

                                        </div>
                                        <div className="d-flex justify-content-between recipt-item">
                                            <h6 className="mb-0">FEES</h6>
                                            <h6 className="mb-0">{calculatedFees}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button className="btn btn-brand-dark btn-lock-back d-flex align-items-center" onClick={handleBackClick}>
                                        <img className="img-btn-lock-back" src="../../images/back-arrow.svg" width="25px" />
                                    </button>
                                    <div className="d-flex justify-content-around approve-lock-btn-container">
                                        <button className="approve-btn pl-4" disabled={isApproveDoneOrInProgress()} onClick={() => handleApprove(tokenAddress, token)}>
                                            <div className="d-flex justify-content-between approve-btn-content align-items-center">
                                                <div className="pr-3 m-auto d-flex align-items-center">
                                                    <img src="../../images/approve-btn-right-icon.svg" className="btn-icon" />
                                                    APPROVE
                                                </div>
                                                {requestedApproval && (<Spinner className="approve-btn-right-number" animation="grow" />)}
                                                {!requestedApproval && (<h5 className="approve-btn-right-number mb-0">1</h5>)}
                                            </div>
                                        </button>
                                        <div className={"connector-line" + (isApproveDone() ? " connector-line-sucess" : "")}></div>
                                        <button className="lock-btn pr-4" disabled={!isApproveDone()} onClick={() => handleCreateLock(tokenAddress)}>
                                            <div className="d-flex justify-content-between lock-btn-content align-items-center">
                                                {lockRequested && (<Spinner className="lock-btn-left-number" animation="grow" />)}
                                                {!lockRequested && (<h5 className="lock-btn-left-number mb-0">2</h5>)}
                                                <div className="pl-4 m-auto d-flex align-items-center">
                                                    <img src="../../images/lock-btn-icon.svg" className="btn-icon" />
                                                    LOCK
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                {approveTxhash && (
                                    <div className="approve-tx-hash">
                                        <a className="approve-tx-hash-link pl-4" target="_blank" href={explorerUrl + 'tx/' + approveTxhash} >{"View on " + getExplorerName(chainId)}</a>
                                    </div>
                                )}
                                {/* <div className="d-flex justify-content-between btn-container">
                                    <button className="btn btn-brand-dark btn-lock-back" onClick={handleBackClick}>
                                        <img src="../../images/back-arrow.svg" width="20px"/>
                                    </button>
                                </div> */}
                            </div>
                        )}
                        {currentStep === 6 && (
                            <div className="step track">
                                <h4 className="font-bold step-title">Track Your Lock</h4>
                                <div className="vault-sucess-step-container text-center">
                                    <img alt="sucess icon" className="vault-success-icon" src="../../images/vault-success.svg" />
                                    <h3 className="font-bold">Success!</h3>
                                    <h6 className="brand-color-grey mb-3">{"Your " + token?.symbol + " tokens are being successfully locked with Blockius Vault."}</h6>
                                    <div className="d-flex justify-content-center">
                                        <a className="btn btn-brand mr-3" href={"/vault-vesting/" + tokenAddress.toLocaleLowerCase()}>TRACK</a>
                                        <a className="btn btn-brand-border" target="_blank" href={explorerUrl + '/tx/' + lockTxhash} >{"View on " + getExplorerName(chainId)}</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
            <VestingTable
                date={multpleUnlockTimestamp}
                tokenValue={lockAmount / vestingLockCount}
                show={vestingTableShow}
                onHide={() => setVestingTableShow(false)}
            />
        </>
    )
}

export default CreateVestingLock