import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useWeb3React } from '@web3-react/core'
import connectors from './config'
import { injected, walletconnect, chainSelectionDetails } from '../../connectors'
import './wallet-connect.css'
import { toast } from 'react-toastify'

export declare type ConnectorId = "authereum" | "fortmatic" | "frame" | "injected" | "portis" | "squarelink" | "torus" | "walletconnect" | "walletlink" | "bsc";

function ConnectWallet(props: any) {

    const { activate, chainId, deactivate } = useWeb3React()
    const [isChainSelectOpenWalletConnect, setIsChainSelectOpenWalletConnect] = useState(false)
    const [isChainSelectOpenTrustWallet, setIsChainSelectOpenTrustWallet] = useState(false)
    const [selectedChainId, setSelectedChainId] = useState(56)

    const handleLogin = (connecter: ConnectorId, userSelectedChainId = 0, isTrustWallet = false) => {
        props.onHide()
        if (connecter === "walletconnect" && userSelectedChainId) {
            walletconnect(chainId, isTrustWallet).walletConnectProvider = undefined
            return activate(walletconnect(userSelectedChainId, isTrustWallet), undefined, true)
                .then(async () => {
                    window.localStorage.setItem('connectorId', connecter);
                    console.log(walletconnect(userSelectedChainId, isTrustWallet).walletConnectProvider)
                    // if (chainId !== userSelectedChainId) {
                    //     console.log("asdf asdf asdf ", chainId, userSelectedChainId)
                    // }
                    // let data = await walletconnect(chainId).walletConnectProvider.enable();
                    // await activate(walletconnect(chainId), undefined, true)
                    // console.log(data, walletconnect(chainId).walletConnectProvider)
                }).catch((e) => {
                    // alert(e.message)
                    console.log(e)
                    toast.error(e.message)
                })
        }
        return activate(injected, undefined, true)
            .then(() => {
                window.localStorage.setItem('connectorId', connecter);
            }).catch((e) => {
                // alert(e.message)
                console.log(e)
                toast.error(e.message)
            })
    }

    useEffect(() => {
        let connector = window.localStorage.getItem('connectorId');
        if (chainId && selectedChainId && connector && connector === 'walletconnect') {
            if (chainId !== selectedChainId) {
                console.log("networkChainId ", chainId, "selectedChainId ", selectedChainId)
                deactivate()
                window.localStorage.removeItem('connectorId');
                window.localStorage.removeItem('walletconnect');
                window.localStorage.removeItem('userSelectedChainId')
                toast.error("Oops! You might have selected to different chain than the one selected in your wallet.");
            } else {
                window.localStorage.setItem('userSelectedChainId', selectedChainId.toString());
            }
        }
    }, [chainId, selectedChainId])

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="brand-color-white"
        >
            <Modal.Header closeButton className="brand-bgcolor-shadow-black">
                <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                    <h5 className='mb-0'> Connect Wallet</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="brand-bgcolor-shadow-black">
                <div>
                    <div className="wallet-item"
                        onClick={() => {
                            handleLogin("injected")
                        }}>
                        <div className="d-flex align-items-center connect-details-container metamask-img-padding-fix">
                            <img alt="metamask" width="35px" src="../images/wallet-metamask.svg" />
                            <h5 className="ml-4 mb-0 connector-name">Metamask</h5>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="wallet-item">
                        <div className={`d-flex align-items-center connect-details-container ${isChainSelectOpenWalletConnect && 'border-bottom-curvy'}`} onClick={() => {
                            setIsChainSelectOpenWalletConnect(!isChainSelectOpenWalletConnect)
                            setIsChainSelectOpenTrustWallet(false)
                            // handleLogin("walletconnect")
                        }}>
                            <img alt="wallet-connect" width="35px" src="../images/wallet-walletConnection.svg" />
                            <h5 className="ml-4 mb-0 connector-name">Wallet Connect</h5>
                        </div>
                        {isChainSelectOpenWalletConnect && (<div className='p-3'>
                            <div>
                                <h6 className='mb-0 ml-2'>Choose Network</h6>
                                <div className='row network-container'>
                                    {chainSelectionDetails.map((chainData, index) => (
                                        <div key={index} className='col-4 px-1 py-1'>
                                            <div className={`network-inner-item d-flex flex-column align-items-center ${selectedChainId === chainData.chainId && "selected-network"}`} onClick={() => setSelectedChainId(chainData.chainId)}>
                                                <div className='mb-2 chain-icon-img'>
                                                    <img alt={chainData.icon} width="43px" src={chainData.icon}/>
                                                    {selectedChainId === chainData.chainId && (
                                                        <div className='selected-chain-icon'>
                                                            <img className='' width="17px" src="../images/selectChainIcon/selected.svg"></img>
                                                        </div>
                                                    )}
                                                </div>
                                                <h6 className='chain-name-below-img'>{chainData.title}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button className="btn btn-brand-header text-nowrap wallet-connect-btn" onClick={() => handleLogin("walletconnect", selectedChainId)}>
                                    Connect
                                </button>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div>
                    <div className="wallet-item">
                        <div className={`d-flex align-items-center connect-details-container metamask-img-padding-fix ${isChainSelectOpenTrustWallet && 'border-bottom-curvy'}`} onClick={() => {
                            setIsChainSelectOpenTrustWallet(!isChainSelectOpenTrustWallet)
                            setIsChainSelectOpenWalletConnect(false)
                            // handleLogin("walletconnect")
                        }}>
                            <img alt="wallet-connect" width="35px" src="../images/wallet-trustwallet.svg" />
                            <h5 className="ml-4 mb-0 connector-name">Trust Wallet</h5>
                        </div>
                        {isChainSelectOpenTrustWallet && (<div className='p-3'>
                            <div>
                                <h6 className='mb-0 ml-2'>Choose Network</h6>
                                <div className='row network-container'>
                                    {chainSelectionDetails.map((chainData, index) => (
                                        <div key={index} className='col-4 px-2 py-2'>
                                            <div className={`network-inner-item d-flex flex-column align-items-center ${selectedChainId === chainData.chainId && "selected-network"}`} onClick={() => setSelectedChainId(chainData.chainId)}>
                                                <div className='mb-2 chain-icon-img'>
                                                    <img alt={chainData.icon} width="43px" src={chainData.icon}/>
                                                    {selectedChainId === chainData.chainId && (
                                                        <div className='selected-chain-icon'>
                                                            <img className='' width="17px" src="../images/selectChainIcon/selected.svg"></img>
                                                        </div>
                                                    )}
                                                </div>
                                                <h6 className='chain-name-below-img'>{chainData.title}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button className="btn btn-brand-header text-nowrap wallet-connect-btn" onClick={() => handleLogin("walletconnect", selectedChainId, true)}>
                                    Connect
                                </button>
                            </div>
                        </div>)}
                    </div>
                </div>
                {/* {connectors.map((entry, index) => (
                    <div key={index}>
                        <div className="wallet-item"
                            onClick={() => {
                                handleLogin(entry.connectorId)
                            }}>
                            <div className="d-flex p-3 align-items-center">
                                <img alt={entry.icon} width="40px" src={entry.icon} />
                                <h5 className="ml-4 mb-0">{entry.title}</h5>
                            </div>
                        </div>
                    </div>
                ))} */}
            </Modal.Body>
        </Modal>
    );
}

export default ConnectWallet