import React from 'react'
import { Modal } from 'react-bootstrap'
import './vesting-table.scss'

function VestingTable(props: any) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="brand-color-white"
        >
            <Modal.Header closeButton className="brand-bgcolor-shadow-black">
                <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                    Vesting Table
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="brand-bgcolor-shadow-black">
                {/* <h6 className="text-center text-truncate">{props.date}</h6> */}
                <table className="vesting-table">
                <colgroup>
                        <col span={1} width='auto' />
                        <col span={1} width='auto' />
                    </colgroup>
                    <thead>
                        <tr className="text-center">
                            <th className="pl-2">Sr. No</th>
                            <th>Unlock Time</th>
                            <th>Tokens Locked</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.date.length < 100 && props.date.map((timeStamp: any, index: number) => (
                            <tr key={`tr-${index}`} className="text-center">
                                <td className="pl-2">{index + 1}</td>
                                <td>{new Date(timeStamp * 1000).toUTCString().replace('GMT', 'UTC')}</td>
                                {/* <td>{props.tokenValue.toFixed(4)}</td> */}
                                <td>{Math.floor(props.tokenValue * 10000) / 10000}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* <div className="d-flex justify-content-around mt-4 mb-3">
                </div> */}
            </Modal.Body>
        </Modal>
    )
}

export default VestingTable

{/* <table className='vault-table'>
                    <colgroup>
                        <col span={1} width='auto' />
                        <col span={1} width='auto' />
                        <col span={1} width='auto' />
                        <col span={1} width='auto' />
                        <col span={1} width='auto' />
                        <col span={1} width='20%' />
                    </colgroup>
                    <thead>
                        <tr className="text-center">
                            <th className="pl-5">Name</th>
                            <th>blockchain</th>
                            <th>Token Locked</th>
                            <th>total value locked</th>
                            <th>next realease</th>
                            <th className="pr-5"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((obj, index) => (
                            <tr key={`tr-${index}`} className="text-center">
                                <td className="pl-5">{obj.name}</td>
                                <td>{obj.chain}</td>
                                <td className="text-center">
                                    <NumberFormat
                                        value={obj.tokenLocked}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                </td>
                                <td className="text-center">
                                    <NumberFormat
                                        value={obj.totalValueLocked}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                    />
                                </td>
                                <td className="text-center">{dayjs(dayjs.unix(obj.nextReleaseTime).format("YYYY-MM-DD HH:mm:ss")).fromNow(true)}</td>
                                <td className='button-cell pr-5'>
                                    <a className='py-2 btn btn-brand btn-block' href={"/vault/"+ obj.address}>View</a>
                                    
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}