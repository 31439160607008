import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import useRefresh from './useRefresh'
import { isAddress } from '../utils'
import { getTokenContract } from '../utils/contractHelpers'
import { getLpVaultAddress, getVestingVaultAddress } from '../utils/addressHelpers'

export const useAllowance = (tokenAddress: string, type: 'token' | 'pair'): BigNumber | null => {
    const [allowance, setAllowance] = useState(new BigNumber(0))
    const { account, library, chainId } = useWeb3React()
    const address = isAddress(tokenAddress)
    const { fastRefresh } = useRefresh()

    useEffect(() => {
        const fetchAllowance = async () => {
            if (!address || !account || !chainId) return null
            try {
                const tokenContract = getTokenContract(tokenAddress, library, account)
                const res = await tokenContract?.allowance(
                    account,
                    type === 'token'
                        ? getVestingVaultAddress(chainId)
                        : getLpVaultAddress(chainId))
                setAllowance(new BigNumber(res.toString()))
            }
            catch (error) {
                console.log(error)
                return null
            }
        }

        if (account) {
            fetchAllowance()
        }
    }, [account, fastRefresh, tokenAddress])



    return allowance
}